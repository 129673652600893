<template>
  <div class="container-medicine">
    <p class="title-medicine">Thành phần hoạt chất</p>
    <div class="frame-content">
      <div class="line-content"></div>
      <img src="@/assets/img/obb-icon.png" alt="" />
      <div class="line-content"></div>
    </div>
    <div class="container">
      <div class="frame-table">
        <div class="table">
          <div class="col-1">
            <div class="tb-title">THÀNH PHẦN HOẠT CHẤT (30ML)</div>
            <div class="tb-dl">ĐỊNH LƯỢNG</div>
          </div>
          <div class="col-2">
            <div class="tb-title" style="margin-left: 40px; text-align: start">
              THÔNG TIN THÊM
            </div>
          </div>
        </div>
        <div
          class="table-content"
          v-for="(item, index) in listMedicine"
          :key="index"
        >
          <div
            class="col-1"
            :class="item.img ? 'cell-active' : ''"
            @click="btClickCell(item)"
          >
            <div style="width: 100%">
              <div class="col-chemistry">
                <div class="tb-title" :class="item.img ? 'title-active' : ''">
                  <span
                    >{{ item.name }}
                    <img
                      src="@/assets/img/obb-icon-w.png"
                      alt=""
                      v-if="item.img"
                  /></span>
                </div>
                <div class="tb-dl" :class="item.img ? 'title-active' : ''">
                  {{ item.dl }}
                </div>
              </div>
              <div class="des-mobile" v-if="item.img">
                <p class="des-title">CÔNG DỤNG</p>
                <div class="des-sub">{{ item.des }}</div>
              </div>
            </div>
          </div>
          <div class="col-2 des-desktop" v-if="item.img">
            <div class="tb-title">{{ item.des }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listMedicine: [
        {
          name: "Lysin Hydrochlorid",
          dl: "500 mg",
          img: true,
          des: "Lysine là một trong 12 axit amin thiết yếu cần có trong bữa ăn hằng ngày. Nó giúp tăng cường hấp thụ và duy trì canxi, ngăn cản sự bài tiết khoáng chất này ra ngoài cơ thể. Nhờ vậy, lysine có tác dụng tăng trưởng chiều cao, ngăn ngừa bệnh loãng xương. Lysine là một acid amin có những vai trò rất lớn đối với sức khỏe của con người, đặc biệt là với trẻ em, Lysine không thể thiếu trong chế độ dinh dưỡng để giúp trẻ ăn ngon, phát triển chiều cao và thể lực. Lysine là thành phần của nhiều loại protein, là yếu tố quan trọng trong việc duy trì hệ miễn dịch, phát triển men tiêu hóa, kích thích ăn ngon. Việc thiếu hụt chất này có thể khiến trẻ chậm lớn, biếng ăn, dễ thiếu men tiêu hóa và nội tiết tố. Do sự bất hợp lý về khẩu phần nên người Việt dễ bị thiếu lysine.",
        },
        {
          name: "Beta Glucan",
          dl: "450 mg",
          img: false,
          des: "Beta-glucan giúp tăng cường hoạt động của các đại thực bào và kích thích tăng tiết nhiều cytokines (chất hoạt hóa tế bào) nhằm tiêu diệt các mầm bệnh xâm nhập từ bên ngoài, giúp giảm hệ số chuyển đổi thức ăn, kích thích tiêu hóa, phòng các bệnh đường ruột, nhiễm trùng do vi khuẩn, virus. Đặc biệt, loại thực phẩm có chứa Beta Glucan còn có tác dụng rõ rệt trong việc làm tăng nhu động ruột, cải thiện các vấn đề về đường tiêu hóa hiệu quả. Sau khi được ăn/ uống vào cơ thể qua đường tiêu hóa, Beta-Glucan 1,3/1,6 được hấp thu qua ruột non. Trong các cơ quan miễn dịch, nó liên kết với các bạch cầu trung tính và tăng cường “sức mạnh” cho chúng. Các bạch cầu trung tính được tăng cường sẽ nhận dạng và tiêu diệt các mầm bệnh xâm nhập từ bên ngoài một cách hiệu quả hơn.",
        },
        {
          name: "Echinacea",
          dl: "450 mg",
          img: false,
          des: "Echinacea được chiết xuất từ cây hoa cúc tím – chứa các hoạt chất như alkamid, acid cichoric và polysacarit co hiệu quả tăng khả năng miễn dịch cho trẻ. Tác dụng của echinacea làn gia tăng đáng kể và bền vững số lượng tế bào bạch cầu đa nhân, bạch cầu đơn nhân, bạch cầu trung tính và các tế bào tiêu diệt tự nhiên. Echinacea có tác dụng điều trị nhiễm trùng, đặc biệt là cảm lạnh, cảm cúm và một số bệnh nhiễm trùng đường hô hấp trên cho trẻ.",
        },
        {
          name: "Oat (Avena sativa)",
          dl: "250 mg",
          img: false,
          des: "Yến mạch chứa chất xơ hòa tan, protein, các vitamin nhóm B … giúp hỗ trợ cho hệ tiêu hóa và hệ miễn dịch, ít gây dị ứng, đồng thời kiểm soát được cảm giác thèm ăn không đúng bữa ở trẻ nhỏ. Yến mạch có hàm lượng dinh dưỡng cao, giàu đạm, giàu chất xơ hòa tan, giúp tăng cường hệ miễn dịch vừa giúp cung cấp thêm vitamin và khoáng chất của trẻ. Rất thân thiện với hệ tiêu hóa của trẻ nhỏ. Trong yến mạch có một nhóm chất chống oxy hóa mang tên Avenanthramide. Đây được coi là chất chống viêm cực kỳ an toàn cho làn da của trẻ, tránh gây ngứa ngáy hay khô da.",
        },
        {
          name: "Artichoke (Cynara scolymus)",
          dl: "150 mg",
          img: false,
          des: "Tăng khả năng chuyển hóa các chất béo và hấp thụ vitamin từ thực phẩm. Atisô là một nguồn chất xơ phong phú, giúp chức năng của hệ thống tiêu hóa của trẻ. Chất xơ bổ sung số lượng lớn vào phân, giữ cho nhu động ruột của trẻ thường xuyên và hơn nữa, làm giảm các triệu chứng táo bón. Atisô tốt cho tim mạch. Hai chất chống oxy hóa được tìm thấy trong atisô, cynarin và silymarin. Nghiên cứu đã chứng minh là cải thiện sức khỏe tổng thể của gan bằng cách giảm sự hiện diện của độc tố và tạo điều kiện đào thải chúng khỏi gan và cơ thể. Hơn nữa, phenol và axit có trong atisô đóng vai trò là chất kích thích, giúp trục xuất các độc tố có hại ra khỏi cơ thể và cũng thúc đẩy sản xuất mật.",
        },
        {
          name: "Hops Flower (Humulus lupulus)",
          dl: "120 mg",
          img: false,
          des: "Trợ giúp cho giấc ngủ về đêm, khiến trẻ ngủ ngon hơn. Hoa bia là một loại thảo dược được sử dụng điều trị chứng mất ngủ và rối loạn giấc ngủ khác, bồn chồn, kích động, rối loạn tăng động giảm chú ý (ADHD). Hoa bia cũng được sử dụng để cải thiện sự thèm ăn, tăng lưu lượng nước tiểu ở trẻ nhỏ.",
        },
        {
          name: "Vitamin C (Sodium ascorbate)",
          dl: "100 mg",
          img: false,
          des: "Vitamin C đảm bảo cho hệ miễn dịch của trẻ hoạt động hiệu quả, giúp cơ thể chống lại virus, vi khuẩn, ngăn ngừa các bệnh về đường hô hấp, sốt, cảm lạnh, cảm cúm... Nó còn là chất dẫn giúp trẻ hấp thu hiệu quả sắt, canxi, axit folic... từ thực phẩm để xương chắc khỏe. Cơ thể cần vitamin C để tạo ra những kích thích tố chính trong quá trình truyền dẫn tín hiệu từ não bộ, giúp kích thích trí não.",
        },
        {
          name: "Zinc Gluconate",
          dl: "35 mg",
          img: false,
          des: "Kẽm giúp trẻ ăn ngon miệng hơn, tăng hấp thu, tăng tổng hợp chất đạm và phân chia tế bào. Kẽm giúp duy trì và bảo vệ các tế bào vị giác và khứu giác. Nếu cơ thể thiếu kẽm thì sự phân chia tế bào khó xảy ra làm chậm phát triển chiều cao ở trẻ, rối loạn phát triển xương, dậy thì chậm. Nguyên tố kẽm, dưới dạng muối Zinc Gluconate cũng được sử dụng để tăng cường hệ thống miễn dịch, cải thiện sự tăng trưởng và sức khỏe ở trẻ sơ sinh và trẻ em thiếu kẽm, để điều trị cảm lạnh thông thường và nhiễm trùng tai tái phát, cúm, nhiễm trùng đường hô hấp trên, ngăn ngừa và điều trị nhiễm trùng đường hô hấp dưới cũng như được sử dụng cho bệnh sốt rét và các bệnh khác do ký sinh trùng.",
        },
      ],
    };
  },
  methods: {
    btClickCell(data) {
      var lst = this.listMedicine.find((p) => p.img == true);
      lst.img = false;
      data.img = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-medicine {
  padding-bottom: 120px;
  height: 100%;
  .title-medicine {
    font-family: Soup;
    font-size: 40px;
    text-align: center;
    padding: 50px 15px 0;
    margin-bottom: 10px;
    color: #02959c;
    line-height: 1.5;
    // margin: 0 0 1em 0;
  }

  .frame-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    img {
      height: 35px;
      width: 35px;
      display: inline-block;
      vertical-align: text-top;
      margin: auto 16px;
    }
    .line-content {
      //   right: 52%;
      margin-top: 15px;
      width: 60px;
      height: 4px;
      background-color: #02959c;
    }
  }
  .table {
    margin-top: 80px;
    display: flex;
    width: 100%;
    .col-1 {
      float: left;
      width: 50%;
      color: white;
      background: #02959c;
      padding: 30px 0 20px 0px;
      border-top-left-radius: 50px;
      border-bottom: solid 5px white;
      font-family: "Quicksand Bold";
      font-size: 20px;
      margin-bottom: -12px;
      text-transform: uppercase;
      display: flex;
      .tb-title {
        border-right: solid 3px white;
        width: 66.6667%;
      }
      .tb-dl {
        border-right: solid 3px white;
        width: 33.3333%;
      }
    }
    .col-2 {
      float: left;
      width: 50%;
      color: white;
      background: #02959c;
      padding: 30px 0 20px 0px;
      border-top-right-radius: 50px;
      border-bottom: solid 5px white;
      font-family: "Quicksand Bold";
      font-size: 20px;
      margin-bottom: -12px;
      text-transform: uppercase;
    }
  }
  .frame-table {
    position: relative;
  }
  .table-content {
    display: flex;
    width: 100%;
    cursor: pointer;
    .col-1 {
      width: 50%;

      padding: 20px 0 20px 30px;
      border: solid 1px #02959c;
      font-family: "Quicksand Bold";
      font-size: 20px;
      margin-bottom: 0;

      .col-chemistry {
        display: flex;
        .tb-title {
          border-right: solid 3px #02959c;
          width: 63%;
          display: flex;
          margin-left: 12px;

          img {
            display: inline-block;
            margin-left: 10px;
            margin-top: 3px;
            width: 25px;
          }
        }
        .tb-dl {
          width: 35%;
        }
      }

      .des-mobile {
        display: none;
      }
    }
    .col-2 {
      width: 50%;
      color: white;
      background: #02959c;
      font-size: 20px;
      height: calc(100% - 89px);
      position: absolute;
      right: 0;
      top: 89px;
      bottom: 0;
      .tb-title {
        padding: 20px 40px;
        text-align: justify;
        position: absolute;
        line-height: 25px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .container-medicine {
    .title-medicine {
      font-size: 40px;
    }

    .table {
      .col-1 {
        font-size: 15px;
      }
      .col-2 {
        font-size: 15px;
      }
    }

    .table-content {
      .col-1 {
        font-size: 15px;
        .des-mobile {
          display: none;
        }
      }
      .col-2 {
        font-size: 15px;
        height: calc(100% - 81px);

        top: 81px;
      }
    }
  }
}
@media only screen and (max-width: 771px) {
  .container-medicine {
    .title-medicine {
      font-size: 32px;
    }
  }
  .frame-table {
    .table {
      .col-1 {
        width: 100%;
        border-top-right-radius: 50px;
        .tb-dl {
          border: none;
        }
      }
      .col-2 {
        display: none;
      }
    }
    .table-content {
      width: 100%;
      padding: 0 !important;
      .des-desktop {
        display: none;
      }
      .col-1 {
        width: 100%;
        // padding: 0;
        padding-left: 0;
        // padding-bottom: 0;
        .col-chemistry {
          width: 100%;
          // color: black;

          // border: solid 1px #02959c;
          font-family: "Quicksand Bold";
          font-size: 15px;
          margin-bottom: 0;
          text-transform: uppercase;
          display: flex;
          .tb-title {
            border-right: solid 4px #02959c;
            width: 66.6667%;
            img {
              display: inline-block;
              margin-left: 10px;
              margin-top: -4px;
              width: 25px;
            }
          }
          .tb-dl {
            width: 33.3333%;
            border: none !important;
          }
        }
        .des-mobile {
          display: block;
          background: #fff;
          color: #000;
          border-top: 20px solid #02959c;
          margin-bottom: -20px;
          // padding-right: 0;
          .des-sub {
            padding: 0px 20px 20px 20px;
            font-family: "Quicksand Regular";
            font-size: 17px;
            text-transform: lowercase;
            text-align: left;
          }
          .des-title {
            font-family: "Quicksand Bold";
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;
            text-align: left;
            margin: 0;
            margin-top: 20px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
.cell-active {
  background: #02959c;
  color: #fff !important;
}
.title-active {
  border-right: solid 3px #fff !important;
}
@media only screen and (max-width: 650px) {
  .container-medicine .table .col-1 .tb-title {
    border-right: solid 3px white;
    width: 82.6667%;
    text-align: start;
    padding-left: 8%;
  }
  .frame-table .table .col-1 .tb-dl[data-v-b92f8548] {
    /* padding-right: 7%; */
    padding-left: 8%;
    text-align: start;
    border: none;
  }
  .frame-table .table-content .col-1 .col-chemistry .tb-title {
    width: 71.6667%;
    margin-left: 26px;
  }
  .frame-table .table-content .col-1 .des-mobile .des-sub {
    margin-left: 5px;
  }
  .frame-table .table-content .col-1 .des-mobile .des-title {
    margin-left: 23px;
  }
  .frame-table .table-content .col-1 .col-chemistry .tb-dl {
    width: 33.3333%;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .frame-table .table-content .col-1 .col-chemistry .tb-title span {
    text-align: start;
  }
}
@media (max-width: 700px)
{
  .frame-table .table-content .col-1 .des-mobile{
    border-top: 20px;
  }
  .frame-table .table-content .col-1 .des-mobile .des-title{
    padding-top: 20px;
    
  }
}
</style>
