<template>
    <div>
     <bannerwrap/>
     <paregrah/>
     <checkbmi/>
  
   <footerobmi/>
     </div>
</template>

<script>
import bannerwrap from "./components/banner-bmi.vue";
import paregrah from "./components/para-bmi.vue";
import checkbmi from "./components/check-bmi.vue";
import footerobmi from "./components/footer-bmi.vue";
export default {
     components: {
    bannerwrap,
    paregrah,
    checkbmi,
   footerobmi
  },
}
</script>

<style>

</style>