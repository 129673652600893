<template>
  <div class="intro-container">
    <img src="@/assets/img/logo-heading-2.png" alt="" style="width: 350px" />
    <div class="intro-body">
      <div class="frame-img">
        <img src="@/assets/img/obaby-design.png" alt="" width="100%" style="" />
      </div>

      <div class="text-content">
        <p class="title">
          Obaby với cơ chế 3 điểm chạm giúp con ăn ngon và tăng cường sức đề
          kháng
        </p>
        <div class="des1">
          <img alt="" width="100%" />
          <p class="description">
            Kích thích tuyến yên tăng cường chuyển hóa, hấp thu trọn vẹn thức ăn
            với nhóm thành phần Artichoke, Hops Flower, Oat (Yến mạch).
          </p>
        </div>
        <div class="des1">
          <img src="@/assets/img/bg-intro.png" width="100%" alt="" />
          <p class="description">
            Bổ sung acid amin và kẽm giúp bảo vệ các tế bào vị giác và khứu
            giác, giúp trẻ ăn ngon miệng hơn với đa dạng thức ăn, mùi vị
          </p>
        </div>
        <div class="des1">
          <img src="@/assets/img/bg-intro.png" width="100%" alt="" />
          <p class="description">
            Tăng cường sức đề kháng với nhóm thành phần Echinacea, Vitamin C,
            Beta Glucan (Beta-1,3/1,6-D-glucan), Zn
          </p>
        </div>
      </div>
    </div>
    <div class="intro-content">
      <div class="lst-feedback container">
        <div class="frame-feedback">
          <img class="avatar" src="@/assets/img/mom-3.png" alt="" />
          <img class="choose" src="@/assets/img/toi-chon.png" alt="" />
          <p class="mom-name">Mẹ bé Việt Dũng - Phú Yên</p>
          <p class="mom-loc">OBABY CÓ TÁC DỤNG NGẮN HẠN RẤT RÕ RỆT</p>
          <p class="mon-txt">
            “Thật tuyệt với, đã rất lâu tôi mới cảm nhận được việc con ăn uống
            ngon miệng hơn chỉ sau thời gian ngắn sử dụng Obaby. Cám ơn Obaby,
            cám ơn BS Vinh (GĐ BV Đa khoa Phú Yên) đã chỉ cho tôi biết sản phẩm
            này.”
          </p>
        </div>
        <div class="frame-feedback" style="background: #02959c">
          <img class="avatar" src="@/assets/img/mom-2.png" alt="" />
          <img class="choose" src="@/assets/img/toi-chon.png" alt="" />
          <p class="mom-name">Mẹ bé Nhật Minh - Thái Nguyên</p>
          <p class="mom-loc">NÊN SỬ DỤNG OBABY ĐỦ LIỆU TRÌNH</p>
          <p class="mon-txt">
            "Bé Bo nhà mình ăn uống rất tốt nhưng sau khi đi trẻ, con ăn uống
            kém dần, bữa sữa buổi tối cũng bỏ uống luôn. Khoảng hết hộp thứ nhất
            bé Bo đã có thói quen ăn uống rất đúng bữa, kể cả uống sữa trước khi
            đi ngủ."
          </p>
        </div>
        <div class="frame-feedback" style="background: #02959c">
          <img class="avatar" src="@/assets/img/mom-1.png" alt="" />
          <img class="choose" src="@/assets/img/toi-chon.png" alt="" />
          <p class="mom-name">Mẹ bé Hoài Nam - TP Bắc Giang</p>
          <p class="mom-loc">
            OBABY CẢI THIỆN ĐÁNG KỂ VIỆC GIẢM MẮC CÁC BỆNH ĐƯỜNG HÔ HẤP DO ĐỀ
            KHÁNG KÉM
          </p>
          <p class="mon-txt">
            "Không gì khổ bằng con đau ốm quanh năm, cái vòng tròn biếng ăn, đề
            kháng kém, hay ốm, rồi lại biếng ăn ám ảnh gia đình từ khi bé hết ăn
            dặm. 1 tháng đi viện 4 lần là bình thường. Tôi được BS Quyết khám và
            chỉ cho tôi dùng Obaby 123 cho con. Trộm vía, con ăn uống ổn định,
            ít nôn trớ, và đặc biệt là nickname Ốm vặt đã không còn là của con
            nữa. Giờ lâu lâu mới mua cho con 1 hộp Obaby chứ không cần dùng
            thường xuyên nữa."
          </p>
        </div>
        <div class="frame-feedback">
          <img class="avatar" src="@/assets/img/mom-4.png" alt="" />
          <img class="choose" src="@/assets/img/toi-chon.png" alt="" />
          <p class="mom-name">Mẹ bé Như Anh - Hà Nội</p>
          <p class="mom-loc">
            OBABY 123 CHỈ 1 ML/LẦN/NGÀY. XI LANH PHÂN LIỀU RẤT DỄ SỬ DỤNG.
          </p>
          <p class="mon-txt">
            “Bé nhà mình nói không với mọi loại thuốc trên đời, kể cả hạ sốt
            cũng chỉ dùng được viên đặt. Nhưng với xi lanh nhỏ xinh của Obaby
            123 thì con hợp tác vô cùng, miệng chúm chím như chim mỗi khi mẹ cho
            uống. Uống ít cũng là 1 lợi thế với các bé còn nhỏ như bé nhà mình.
            Obaby 123 mùi vị rất thơm ngon, dễ uống, chỉ 1 ml mỗi ngày cho con
            thôi. Mình mua 1 hộp Obaby 123 có thể cho con dùng được 1 tháng, rất
            hợp lý nhé các mẹ.”.
          </p>
        </div>
      </div>
    </div>

    <div class="intro-bottom">
      <p class="title">Đừng biến bữa ăn của con thành một cuộc chiến</p>
      <div class="frame-content">
        <div class="line-content"></div>
        <img src="../../../assets/img/obb-icon-w.png" alt="" />
        <div class="line-content"></div>
      </div>
      <p class="sub">
        Hãy làm mẹ <span style="color: #f5df50">thông thái </span> <br />
        không thông thái thì <span style="color: #f5df50">con thiệt thòi</span>
      </p>
      
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.intro-container {
  background-color: #f5f5f5;
  background-image: url("@/assets/img/bg-star.png");
  background-position: center;
  img {
    margin-top: 50px;
  }
  .intro-body {
    display: flex;
    .frame-img {
      margin-left: -20px;
      width: 45%;
      img {
        max-width: 70%;
        float: right;
      }
    }
    .text-content {
      width: 55%;
      .title {
        font-family: "Quicksand Bold";
        font-size: 23px;
        text-align-last: center;
        max-width: 450px;
        margin-left: 119px;
        margin-top: 50px;
        line-height: 1.4;
        color: #02959c;
      }
      .des1 {
        text-align: start;
        margin-bottom: 20px;
        .description {
          font-family: "Quicksand Bold";
          font-size: 18px;
          text-align-last: center;
          max-width: 480px;
         
          position: absolute;
          line-height: 1.4;
          color: #fff;
          margin-top: -90px;
        }
        img {
          margin-top: 10px;
          width: 72%;
          text-align: start;
          margin-left: 40px;
          content: url("@/assets/img/bg-intro.png") !important;
        }
      }
    }
  }
  .intro-content {
    background-image: url("@/assets/img/bg-moon.png");
    background: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // height: 1000px;
    .lst-feedback {
      // margin-top: 100px;
      padding-top: 100px;
      padding-bottom: 100px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      color: #fff;
      .frame-feedback {
        position: relative;
        // width: 50%;
        background: #e35b89;
        padding: 100px 30px 30px;
        border-radius: 50px;
        margin: 80px 10px 20px;
      }
      .mom-name {
        font-family: "Soup";
        font-size: 27px;
        margin-bottom: 5px;
        text-align: left;
      }
      .mom-loc {
        text-transform: uppercase;
        font-size: 17px;
        font-family: "Quicksand Bold";
        text-align: justify;
      }
      .mon-txt {
        font-family: "Quicksand Regular";
        font-size: 16px;
        text-align: justify;
      }
      .choose {
        margin-top: -60px;
        margin-right: 30px;
        float: right;
      }
      .avatar {
        border-radius: 50%;
        position: absolute;
        max-width: 170px;
        top: -125px;
        left: 50px;
      }
    }
  }
  .intro-bottom {
    background-image: url("@/assets/img/muangay.jpg");
    padding-top: 70px;
    padding-bottom: 70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    .title {
      font-family: Soup;
      font-size: 30px;
      text-align: center;
      padding: 15px;
      margin-bottom: 0;
      color: white;
    }
    .frame-content {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      img {
        height: 35px;
        width: 35px;
        display: inline-block;
        vertical-align: text-top;
        margin: auto 16px;
      }
      .line-content {
        //   right: 52%;
        margin-top: 15px;
        width: 60px;
        height: 4px;
        background-color: #ffffff;
      }
    }
    .sub {
      margin: 40px auto;
      font-family: Bebas;
      font-size: 55px;
      line-height: 60px;
      margin-bottom: 10px;
      color: white;
      text-shadow: 2px 2px 10px black;
    }
    p {
      margin-top: 40px;
      .mn-button {
        color: white;
        padding: 10px 120px;
        font-family: Bebas;
        font-size: 35px;
        background: #02959c;
        border-radius: 28px;
        cursor: pointer;
        text-decoration: none !important;
      }
    }
  }
}
@media (min-width: 2250px){
   .intro-container .intro-body .text-content .des1 .description{
    margin-left: 220px;
    font-size: 20px;
    margin-top: -120px
  }
}
@media only screen and (max-width: 2250px) and (min-width: 1600px){
  .intro-container .intro-body .frame-img img{
    width: 58%;
  }
  .intro-container .intro-body .text-content .des1{
    width: 80%;
    position: relative;
  }
  .intro-container .intro-body .text-content .des1 img{
    width: 78%;
  }
  .intro-container .intro-body .text-content .des1 .description{
    margin-left: 100px;

  }
}
@media only screen and (max-width: 1600px) and (min-width: 1400px){

 
     .intro-container .intro-body .text-content .des1 .description{
  
    margin-left: 90px;
  }
      
  }
 
@media only screen and (max-width: 1400px) and (min-width: 1200px)
{
  .intro-container .intro-body .text-content .des1 img{
    width: 95%;
    margin-left: 10px;
  }
  .intro-container .intro-body .text-content .title{
    margin-left: 70px;
  }
  .intro-container .intro-body .frame-img img{
    max-width: 90% !important;
  }
  .intro-container .intro-body .text-content .des1 .description{
    max-width: 460px;
    margin-left: 70px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 991px){
     .intro-container .intro-body .text-content .des1 img{
    width: 90%;
    margin-left: 10px;
  }
  .intro-container .intro-body .text-content .title{
    margin-left: 70px;
  }
   .intro-container .intro-body .text-content .des1 .description{
    max-width: 400px;
    margin-left: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .intro-container {
    img {
      margin-top: 50px;
      width: 100%;
      max-width: 400px !important;
      height: auto;
    }
    .intro-body {
      display: block;
      .frame-img {
        width: 100% !important;
        margin-left: 0;
        img {
          float: none;
        }
      }
      .text-content {
        width: 100%;
        .title {
          font-family: "Quicksand Bold";
          font-size: 24px;
          text-align-last: center;
          max-width: 450px;
          margin: 50px auto 30px;
          line-height: 1.4;
          color: #02959c;
        }
        .des1 {
          margin-bottom: 28px !important;
          display: flex;
          justify-content: center;
          .description {
            font-family: "Quicksand Bold";
            font-size: 18px;
            text-align-last: center;  

            // margin: 0 auto 30px;
            line-height: 1.4;
            color: #fff;
            margin-top: 20px !important;
                max-width: 61% !important;
          }
          img {
            content: url("@/assets/img/bg-intro-m.png") !important;
            margin-top: 10px;
            width: 100% !important;
            max-width: 85% !important;
            height: 112px;
            
          }
        }
      }
    }
    .intro-content {
      background-image: url("@/assets/img/bg-moon.png");
      background: #f5f5f5;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      // height: 1000px;
      .lst-feedback {
        // margin-top: 100px;
        padding-top: 100px;
        padding-bottom: 100px;
        width: 100%;
        display: block;
        // grid-template-columns: auto auto;
        color: #fff;
        .frame-feedback {
          position: relative;
          // width: 50%;
          background: #e35b89;
          padding: 80px 30px 30px;
          border-radius: 50px;
          margin: 80px 10px 20px;
          .mom-name {
            font-family: "Soup";
            font-size: 27px;
            margin-bottom: 5px;
            text-align: left;
          }
          .mom-loc {
            text-transform: uppercase;
            font-size: 17px;
            font-family: "Quicksand Bold";
            text-align: justify;
          }
          .mon-txt {
            font-family: "Quicksand Regular";
            font-size: 16px;
            text-align: justify;
          }
          .choose {
            margin-top: -50px;
            margin-right: 30px;
            height: 50px;
            width: auto;
            float: right;
          }
          .avatar {
            border-radius: 50%;
            position: absolute;
            max-width: 150px !important;
            top: -125px;
            left: 50px;
          }
        }
      }
    }
    .intro-bottom {
      background-image: url("@/assets/img/muangay.jpg");
      padding-top: 1px;
      padding-bottom: 40px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      text-align: center;
      .title {
        font-family: Soup;
        font-size: 25px;
        text-align: center;
        padding: 15px;
        margin-bottom: 0;
        color: white;
      }
      .frame-content {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        img {
          height: 35px;
          width: 35px;
          display: inline-block;
          vertical-align: text-top;
          margin: auto 16px;
        }
        .line-content {
          //   right: 52%;
          margin-top: 15px;
          width: 60px;
          height: 4px;
          background-color: #ffffff;
        }
      }
      .sub {
        margin: 20px auto;
        font-family: Bebas;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 10px;
        color: white;
        text-shadow: 2px 2px 10px black;
      }
      p {
        margin-top: 40px;
        .mn-button {
          color: white;
          padding: 10px 80px;
          font-family: Bebas;
          font-size: 25px;
          background: #02959c;
          border-radius: 28px;
          cursor: pointer;
          text-decoration: none !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .intro-container {
    img {
      margin-top: 50px;
      width: 100%;
      max-width: 400px !important;
      height: auto;
    }
    .intro-body {
      display: block;
      .frame-img {
        width: 100% !important;
        img {
          width: 90%;
          float: none;
        }
      }
      .text-content {
        width: 100%;
        .title {
          font-family: "Quicksand Bold";
          font-size: 20px;
          text-align-last: center;
          max-width: 80%;
          margin: 50px auto 30px;
          line-height: 1.4;
          color: #02959c;
        }
        .des1 {
          margin-bottom: 28px !important;
          .description {
            font-family: "Quicksand Bold";
            font-size: 14px;
            text-align-last: center;
            max-width: 310px;
            // margin: 0 auto 30px;
            line-height: 1.4;
            color: #fff;
            margin-top: -95px;
          }
          img {
            content: url("@/assets/img/bg-intro-m.png") !important;
            margin-top: 10px;
            width: 100% !important;
            max-width: 90% !important;
            height: 100px;
            margin-left: 0;
          }
        }
      }
    }
    .intro-content {
      background-image: url("@/assets/img/bg-moon.png");
      background: #f5f5f5;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      // height: 1000px;
      .lst-feedback {
        // margin-top: 100px;
        padding-top: 100px;
        padding-bottom: 100px;
        width: 100%;
        display: block;
        // grid-template-columns: auto auto;
        color: #fff;
        .frame-feedback {
          position: relative;
          // width: 50%;
          background: #e35b89;
          padding: 60px 30px 30px;
          border-radius: 50px;
          margin: 80px 10px 20px;
          .mom-name {
            font-family: "Soup";
            font-size: 24px;
            margin-bottom: 5px;
            text-align: left;
          }
          .mom-loc {
            text-transform: uppercase;
            font-size: 15px;
            font-family: "Quicksand Bold";
            text-align: justify;
          }
          .mon-txt {
            font-family: "Quicksand Regular";
            font-size: 14px;
            text-align: justify;
          }
          .choose {
            margin-top: -40px;
            margin-right: 0px;
            height: 30px;
            width: auto;
            float: right;
          }
          .avatar {
            border-radius: 50%;
            position: absolute;
            max-width: 120px !important;
            top: -105px;
            left: 30px;
          }
        }
      }
    }
    .intro-bottom {
      background-image: url("@/assets/img/muangay.jpg");
      padding-top: 1px;
      padding-bottom: 40px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      text-align: center;
      .title {
        font-family: Soup;
        font-size: 25px;
        text-align: center;
        padding: 15px;
        margin-bottom: 0;
        color: white;
      }
      .frame-content {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        img {
          height: 35px;
          width: 35px;
          display: inline-block;
          vertical-align: text-top;
          margin: auto 16px;
        }
        .line-content {
          //   right: 52%;
          margin-top: 15px;
          width: 60px;
          height: 4px;
          background-color: #ffffff;
        }
      }
      .sub {
        margin: 20px auto;
        font-family: Bebas;
        font-size: 40px;
        line-height: 60px;
        margin-bottom: 10px;
        color: white;
        text-shadow: 2px 2px 10px black;
      }
      p {
        margin-top: 40px;
        .mn-button {
          color: white;
          padding: 10px 80px;
          font-family: Bebas;
          font-size: 25px;
          background: #02959c;
          border-radius: 28px;
          cursor: pointer;
          text-decoration: none !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px){
  .intro-container img{
    max-width: 90%!important;
  }
}
@media only screen and (max-width: 360px){
    .intro-container .intro-body .text-content .des1 img{
      height: 130px;
    }
    .intro-container .intro-body .text-content .des1 .description
    {
      margin-top: 30px  !important;
    }
   
}
</style>
