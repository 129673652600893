<template>
  <div class="buy-modal">
    <div class="modal-content" v-if="!isShowSuccess">
      <span class="close" @click="btClose">×</span>
      <div class="frame">
        <div class="buy-left">
          <img src="@/assets/img/tuvan.png" alt="" />
        </div>
        <div class="buy-right">
          <p class="buy-lead">ĐĂNG KÝ CHUYÊN GIA DINH DƯỠNG TƯ VẤN</p>

          <div class="input-data">
            <div class="name">
              <input
                type="text"
                placeholder="Họ và tên"
                v-model="formRegister.FullName"
              />
            </div>
            <div class="phone">
              <input
                type="text"
                placeholder="Số điện thoại"
                v-model="formRegister.PhoneNumber"
              />
            </div>
            <div class="age">
              <input
                type="text"
                placeholder="Tuổi con"
                v-model="formRegister.Desctiption"
              />
            </div>
          </div>
          <p
            v-if="isShowWarning"
            class="mt-2"
            style="color: #fff; margin-bottom: -10px"
          >
            {{ contentWarning }}
          </p>
          <button class="bt-register" @click="createWebRegister">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="loadding"
            ></span>
            Đăng ký ngay
          </button>
        </div>
      </div>
    </div>
    <div class="buy-success" v-else>
      <i class="fa-solid fa-circle-check"></i>
      <p class="title">Thành công</p>
      <p class="sub">
        Cám ơn mẹ! Nhân viên CSKH sẽ sớm liên lạc lại với mẹ bé trong thời gian
        sớm nhất!
      </p>
    </div>
    <div
      style="
        position: fixed;
        z-index: -1;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.4);
      "
      @click="btClose"
    ></div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";

export default {
  data() {
    return {
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
      },
      loadding: false,
      isShowWarning: false,
      isShowSuccess: false,
      contentWarning: "Các mẹ vui lòng điền đủ thông tin!",
    };
  },
  methods: {
    btClose() {
      this.$emit("btCloseContent", false);
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.formRegister.Desctiption.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Các mẹ vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }

      this.loadding = true;
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "Obaby",
          TypeDoc: "TV",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.formRegister.Desctiption,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.btClose();
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-modal {
  display: inline-block;
  text-align: center;
  position: fixed;
  z-index: 100;
  padding-top: 150px;
  padding-bottom: 200px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  .buy-success {
    z-index: 102 !important;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    background-color: #fff !important;
    padding: 30px;
    max-width: 400px !important;
    i {
      color: rgb(37, 221, 46);
      font-size: 70px;
    }
    .title {
      font-family: "Quicksand SemiBold";
      font-size: 24px;
      margin-top: 10px;
      color: #02959c;
    }
    .sub {
      font-family: "Quicksand Medium";
      font-size: 18px;
      color: #02959c;
    }
  }

  .modal-content {
    z-index: 101;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    background-image: url("@/assets/img/bg-buy.jpg");
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 80% !important;

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      color: white;
      cursor: pointer;
      width: 16px;
      font-size: 28px;
      font-weight: bold;
    }
    .frame {
      display: flex;
    }
    .buy-left {
      width: 40%;
      img {
        max-width: 420px;
        margin: 30px auto 0;
        width: 100%;
      }
    }
    .buy-right {
      width: 60%;
      margin-right: 30px;
      .buy-lead {
        font-family: "Quicksand Bold";
        font-size: 33px;
        color: white;
        text-transform: uppercase;
        text-shadow: 2px 2px 10px #445;
        margin: 60px;
        width:90%;
        margin-left: 10px;
      }
      .input-data {
        margin-top: -18px;
        margin-left: 0px;
        width: 100%;
        
        .name {
          margin-bottom: 20px;
          width: 100%;
        }
        .phone {
          margin-bottom: 20px;
          width: 100%;
        }
        input {
          width: 100%;
          height: 40px;
          font-size: 17px !important;
          border: none !important;
          border-radius: 0 !important;
          font-family: "Quicksand Regular" !important;
          outline: 0 !important;
          padding: 10px !important;
          margin-bottom: 30px;
        }
      }
      .bt-register {
        text-transform: uppercase;
        border: none;
        background: #02959c;
        color: #fff;
        font-family: "Quicksand Bold" !important;
        font-size: 20px;
        margin-top: 20px;
        width: 100%;
        height: 44px;
        padding: 12px auto;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .buy-modal {
    .modal-content {
      display: block;
      width: 100%;
      .close {
        top: 20px;
        right: 20px;
      }
      .frame {
        display: block;
        width: 100%;
        .buy-left {
          width: 100%;
          img {
            max-width: 220px;
            margin: 20px auto 0;
          }
        }
        .buy-right {
          width: 100%;
          padding: 20px;
          margin-top: -30px;
          .buy-lead {
            font-size: 23px;
            margin: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>
