<template>
  <div>
    <notiModal
      v-if="isShowNoti"
      :dataDistrict="dataDistrict"
      @btClose="btClose"
    />
    <localModal
      v-if="isShowLocal"
      :dataDistrict="dataDistrict"
      :dataShopLst="dataShopLst"
      @btClose="btCloseLocal"
    />
    <div class="block-title">MUA OBABY NGAY TẠI CÁC NHÀ THUỐC GẦN BẠN</div>
    <div class="container" v-for="(item, index) in dataCityAll" :key="index">
      <div class="db-area">
        <p>
          <img src="@/assets/img/vitri.png" alt="" />
        </p>
      </div>
      <div class="db-areaname">
        <p>{{ item.CityArea }}</p>
      </div>
      <div class="db-table">
        <div class="db-col">
          <button
            class="row-box"
            v-for="(dis, ind) in item.Districts"
            :key="ind"
            @click="btShowNoti(dis)"
          >
            <p>{{ dis.district }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetPlaceByProduct } from "@/api/place";
import notiModal from "../../Dialog/noti-modal.vue";
import localModal from "../../Dialog/local-modal.vue";
export default {
  data() {
    return {
      dataPlaceAll: [],
      dataCityAll: [
        {
          CityArea: "Hà Nội",
          Districts: [
            {
              district: "Quận Ba Đình",
              type: 1,
            },
            {
              district: "Quận Hoàn Kiếm",
              type: 1,
            },
            {
              district: "Quận Tây Hồ",
              type: 1,
            },
            {
              district: "Quận Long Biên",
              type: 1,
            },
            {
              district: "Quận Cầu Giấy",
              type: 1,
            },
            {
              district: "Quận Đống Đa",
              type: 1,
            },
            {
              district: "Quận Hai Bà Trưng",
              type: 1,
            },
            {
              district: "Quận Hoàng Mai",
              type: 1,
            },
            {
              district: "Quận Thanh Xuân",
              type: 1,
            },
            {
              district: "Quận Hà Đông",
              type: 1,
            },
            {
              district: "Quận Nam Từ Liêm",
              type: 1,
            },
            {
              district: "Quận Bắc Từ Liêm",
              type: 1,
            },
            {
              district: "Huyện Đông Anh",
              type: 1,
            },
            {
              district: "Huyện Gia Lâm",
              type: 1,
            },
            {
              district: "Huyện Thanh Trì",
              type: 1,
            },
            {
              district: "Huyện Mê Linh",
              type: 1,
            },
            {
              district: "Huyện Sóc Sơn",
              type: 1,
            },
            {
              district: "Thị Xã Sơn Tây",
              type: 1,
            },
            {
              district: "Huyện Ba Vì",
              type: 1,
            },
            {
              district: "Huyện Phúc Thọ",
              type: 1,
            },
            {
              district: "Huyện Đan Phượng",
              type: 1,
            },
            {
              district: "Huyện Hoài Đức",
              type: 1,
            },
            {
              district: "Huyện Quốc Oai",
              type: 1,
            },
            {
              district: "Huyện Thạch Thất",
              type: 1,
            },
            {
              district: "Huyện Chương Mỹ",
              type: 1,
            },
            {
              district: "Huyện Thanh Oai",
              type: 1,
            },
            {
              district: "Huyện Thường Tín",
              type: 1,
            },
            {
              district: "Huyện Phú Xuyên",
              type: 1,
            },
            {
              district: "Huyện Ứng Hòa",
              type: 1,
            },
            {
              district: "Huyện Mỹ Đức",
              type: 1,
            },
          ],
        },
        {
          CityArea: "Hồ Chí Minh",
          Districts: [
            {
              district: "Quận 1",
              type: 1,
            },
            {
              district: "Quận 2",
              type: 1,
            },
            {
              district: "Quận 3",
              type: 1,
            },
            {
              district: "Quận 4",
              type: 1,
            },
            {
              district: "Quận 5",
              type: 1,
            },
            {
              district: "Quận 6",
              type: 1,
            },
            {
              district: "Quận 7",
              type: 1,
            },
            {
              district: "Quận 8",
              type: 1,
            },
            {
              district: "Quận 9",
              type: 1,
            },
            {
              district: "Quận 10",
              type: 1,
            },
            {
              district: "Quận 11",
              type: 1,
            },
            {
              district: "Quận 12",
              type: 1,
            },
            {
              district: "Quận Bình Tân",
              type: 1,
            },
            {
              district: "Quận Bình Thạnh",
              type: 1,
            },
            {
              district: "Quận Gò Vấp",
              type: 1,
            },
            {
              district: "Quận Phú Nhuận",
              type: 1,
            },
            {
              district: "Quận Tân Bình",
              type: 1,
            },
            {
              district: "Quận Tân Phú",
              type: 1,
            },
            {
              district: "Quận Thủ Đức",
              type: 1,
            },
            {
              district: "Huyện Bình Chánh",
              type: 1,
            },
            {
              district: "Huyện Củ Chi",
              type: 1,
            },
            {
              district: "Huyện Hóc Môn",
              type: 1,
            },
            {
              district: "Huyện Nhà Bè",
              type: 1,
            },
          ],
        },
        {
          CityArea: "Miền Bắc",
          Districts: [
            {
              district: "Hà Giang",
            },
            {
              district: "Cao Bằng",
            },
            {
              district: "Bắc Kạn",
            },
            {
              district: "Tuyên Quang",
            },
            {
              district: "Lào Cai",
            },
            {
              district: "Điện Biên",
            },
            {
              district: "Lai Châu",
            },
            {
              district: "Sơn La",
            },
            {
              district: "Yên Bái",
            },
            {
              district: "Hoà Bình",
            },
            {
              district: "Thái Nguyên",
            },
            {
              district: "Lạng Sơn",
            },
            {
              district: "Quảng Ninh",
            },
            {
              district: "Bắc Giang",
            },
            {
              district: "Phú Thọ",
            },
            {
              district: "Vĩnh Phúc",
            },
            {
              district: "Bắc Ninh",
            },
            {
              district: "Hải Dương",
            },
            {
              district: "Hải Phòng",
            },
            {
              district: "Hưng Yên",
            },
            {
              district: "Thái Bình",
            },
            {
              district: "Hà Nam",
            },
            {
              district: "Nam Định",
            },
            {
              district: "Ninh Bình",
            },
          ],
        },
        {
          CityArea: "Miền Trung",
          Districts: [
            {
              district: "Thanh Hóa",
            },
            {
              district: "Nghệ An",
            },
            {
              district: "Hà Tĩnh",
            },
            {
              district: "Quảng Bình",
            },
            {
              district: "Quảng Trị",
            },
            {
              district: "Thừa Thiên Huế",
            },
            {
              district: "Đà Nẵng",
            },
            {
              district: "Quảng Nam",
            },
            {
              district: "Quảng Ngãi",
            },
            {
              district: "Bình Định",
            },
            {
              district: "Phú Yên",
            },
            {
              district: "Khánh Hòa",
            },
            {
              district: "Kon Tum",
            },
            {
              district: "Gia Lai",
            },
            {
              district: "Đắk Lắk",
            },
            {
              district: "Đắk Nông",
            },
            {
              district: "Lâm Đồng",
            },
            {
              district: "Ninh Thuận",
            },
            {
              district: "Bình Thuận",
            },
          ],
        },
        {
          CityArea: "Miền Nam",
          Districts: [
            {
              district: "Bình Phước",
            },
            {
              district: "Tây Ninh",
            },
            {
              district: "Bình Dương",
            },
            {
              district: "Đồng Nai",
            },
            {
              district: "Bà Rịa - Vũng Tàu",
            },
            {
              district: "Long An",
            },
            {
              district: "Tiền Giang",
            },
            {
              district: "Bến Tre",
            },
            {
              district: "Trà Vinh",
            },
            {
              district: "Vĩnh Long",
            },
            {
              district: "Đồng Tháp",
            },
            {
              district: "An Giang",
            },
            {
              district: "Kiên Giang",
            },
            {
              district: "Cần Thơ",
            },
            {
              district: "Hậu Giang",
            },
            {
              district: "Sóc Trăng",
            },
            {
              district: "Bạc Liêu",
            },
            {
              district: "Cà Mau",
            },
          ],
        },
      ],

      isShowNoti: false,
      isShowLocal: false,
      dataDistrict: {},
      dataShopLst: [],
      dataUpdate: [],
    };
  },
  components: {
    notiModal,
    localModal,
  },
  methods: {
    btShowNoti(data) {
      this.dataDistrict = data;
      console.log(data.district);
      if (data.type == 1) {
        let district = this.parseDistric(data);
        if (data.district == "Quận 1") {
          this.dataUpdate = this.dataPlaceAll.filter(
            (p) => p.District == data.district
          );
        } else {
          this.dataUpdate = this.dataPlaceAll.filter((p) =>
            p.District.includes(district)
          );
        }
      } else {
        this.dataUpdate = this.dataPlaceAll.filter((p) =>
          p.City.includes(data.district)
        );
      }
      if (this.dataUpdate.length == 0) {
        this.isShowNoti = true;
      } else {
        this.isShowLocal = true;
        this.dataShopLst = this.dataUpdate;
      }
    },
    btClose() {
      this.isShowNoti = false;
    },
    btCloseLocal() {
      this.isShowLocal = false;
    },
    getPlaceByProduct() {
      GetPlaceByProduct({ ProductID: "Obaby" }).then((result) => {
        this.dataPlaceAll = result.data.PlaceLst.filter(
          (p) => p.Status == 2 || p.Status == 1
        );
      });
    },
    parseDistric(data) {
      let item = "";
      let lst = [];
      if (data.district.includes("Quận")) {
        lst = data.district.split("Quận");
        item = lst[1];
      }
      if (data.district.includes("Huyện")) {
        lst = data.district.split("Huyện");
        item = lst[1];
      }
      return item;
    },
  },
  created() {
    this.getPlaceByProduct();
  },
};
</script>

<style lang="scss" scoped>
.block-title {
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  color: #02959c;
  padding-bottom: 30px;
  margin-top: 30px;
  font-family: "Quicksand Bold";
  padding-left: 15px;
  padding-right: 15px;
}
.db-area {
  margin-bottom: 30px;
  padding-left: 20px;
  p {
    font-family: "Quicksand Bold";
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: #02959c;
    img {
      float: left;
      width: 32px;
      max-width: 100%;
    }
  }
}
.db-areaname {
  border-bottom: 1px solid #02959c;
  margin-left: 40px;
  font-size: 25px;
  color: #02959c;
  p {
    font-family: "Quicksand Bold";
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: #02959c;
    text-align: left;
    margin-left: 20px;
  }
}
.db-table {
  margin-top: 50px;
  .db-col {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    .row-box {
      margin: 8px;
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #333;
      font-family: "Quicksand Medium";
      font-size: 18px;
      cursor: pointer;
      p {
        margin: 4px 0;
      }
    }
    .row-box:hover {
      background: #02959c;
      color: #fff;
      font-family: "Quicksand Bold";
    }
  }
}

@media only screen and (max-width: 900px) {
  .db-table {
    .db-col {
      grid-template-columns: auto auto auto !important;
    }
  }
}
@media only screen and (max-width: 640px) {
  .db-table {
    .db-col {
      grid-template-columns: auto auto !important;
      .row-box {
        margin: 8px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        font-family: "Quicksand Medium";
        font-size: 16px;
        cursor: pointer;
        p {
          margin: 4px 0;
        }
      }
    }
  }
}
</style>
