<template>
  <div>
    <banner/>
    <local />
    <feedback/>
    <footerobmi/>
  </div>
</template>

<script>
import local from "./components/local-obaby.vue";
import banner from "../Home/components/banner-obaby.vue";
import feedback from "../Home/components/feedback-obaby.vue";
import footerobmi from "../Physical/components/footer-bmi.vue";
export default {
  components: {
    banner,
    local,
    feedback,
    footerobmi
  },
};
</script>

<style></style>
