<template>
  <div class="container-footer">
    <img src="@/assets/img/pre-footer.png" alt="" width="100%"  class = "img-above" />
    <div class="frame-f">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm footer-logo">
            <p class="ft-sub">Thực phẩm bảo vệ sức khỏe</p>
            <div style="width: 100%">
              <img src="@/assets/img/logo-footer.png" alt="" width="100%" />
            </div>

            <div class="social-single">
              <a href="https://fb.com/obaby.vn" target="_blank">
                <i class="fa-brands fa-facebook"></i>
                <span>Fanpage Obaby</span>
              </a>
            </div>
            <div class="social-single">
              <a href="https://fb.com/obaby.vn" target="_blank">
                <i class="fa-brands fa-facebook"></i>
                <span>Fanpage Obaby 123</span>
              </a>
            </div>
            <div class="social-single">
              <a href="tel:0971356356" target="_blank">
                <i class="fa-solid fa-phone"></i>
                <span>0971.356.356</span>
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-sm-0"></div>
          <div class="col-lg-4 col-sm footer-form">
            <p>Đăng ký Chuyên gia Dinh dưỡng tư vấn</p>

            <div class="col-xs-12 f-single">
              <input
                type="text"
                placeholder="Họ và tên mẹ"
                class="input-form"
                v-model="formRegister.FullName"
              />
            </div>
            <div class="col-xs-12 f-single">
              <input
                type="text"
                placeholder="Số điện thoại"
                class="input-form"
                v-model="formRegister.PhoneNumber"
              />
            </div>
            <div class="col-xs-12 f-single">
              <input
                type="text"
                placeholder="Tuổi con"
                class="input-form"
                v-model="formRegister.Desctiption"
              />
            </div>
            <div class="col-xs-12 f-single">
              <p
                v-if="isShowWarning"
                class="mt-2"
                style="color: #fff; margin-bottom: 10px"
              >
                {{ contentWarning }}
              </p>
              <button class="bt-register" @click="createWebRegister">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="loadding"
                ></span>
                Đăng ký ngay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/img/footer.jpg" alt="" style="width: 100%" />
    <div class="bottom-ft">
      <p>
        Sản phẩm này không phải là thuốc, không có tác dụng thay thế thuốc chữa
        bệnh.
      </p>
    </div>
    <div
      style="
        position: fixed;
        z-index: 100;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
      "
      v-if="isShowSuccess"
      @click="isShowSuccess = false"
    >
      <div class="buy-success">
        <i class="fa-solid fa-circle-check"></i>
        <p class="title">Thành công</p>
        <p class="sub">
          Cám ơn mẹ! Nhân viên CSKH sẽ sớm liên lạc lại với mẹ bé trong thời
          gian sớm nhất!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
      },
      loadding: false,
      isShowWarning: false,
      isShowSuccess: false,
      contentWarning: "Các mẹ vui lòng điền đủ thông tin!",
    };
  },
  methods: {
    btClose() {
      this.$emit("btCloseContent", false);
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.formRegister.Desctiption.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Các mẹ vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }

      this.loadding = true;
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "Obaby",
          TypeDoc: "TV",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.formRegister.Desctiption,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-success {
  z-index: 1 !important;
  border-radius: 8px;
  margin: auto;
  overflow: hidden;
  background-color: #fff !important;
  padding: 30px;
  max-width: 400px !important;
  i {
    color: rgb(37, 221, 46);
    font-size: 70px;
  }
  .title {
    font-family: "Quicksand SemiBold";
    font-size: 24px;
    margin-top: 10px;
    color: #02959c;
  }
  .sub {
    font-family: "Quicksand Medium";
    font-size: 18px;
    color: #02959c;
  }
}
.container-footer .frame-f .footer-logo .social-single a span:hover{
  color: #02959c;
}


.container-footer {
  .frame-f {
    background: #000120;
    background-image: url("@/assets/img/bg-footer.png");
    background-position: center;
    .footer-logo {
      img {
        max-width: 300px;
        margin-bottom: 10px;
      }
      .ft-sub {
        font-family: "Quicksand SemiBold";
        font-size: 18px;
        color: white;
      }
      .social-single {
        margin: 0 auto;
        margin-bottom: 20px;
        margin-left: 40px;
        color: #fff;
        text-align: left;

        a {
          text-decoration: none;
          i {
            font-size: 30px;
            color: #fff;
            margin-right: 12px;
          }
          span {
            font-size: 24px;
            margin-right: 15px;
            color: #fff;
          }
        }
        
      }
    }
    .footer-form {
      p {
        font-family: "Quicksand SemiBold";
        font-size: 18px;
        color: white;
        margin-bottom: 50px;
      }
      .input-form {
        font-family: "Quicksand Regular" !important;
        font-size: 17px !important;
        padding: 10px !important;
        float: left !important;
        outline: 0 !important;
        width: 100% !important;
        border: none !important;
        border-radius: 0 !important;
        margin-bottom: 32px;
      }
      .f-button {
        padding: 10px;
        font-family: "Quicksand Bold";
        font-size: 17px;
        width: 100%;
        background: #02959c;
        color: white;
        display: block;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .bottom-ft {
    background: #02959c;
    padding: 10px;
    p {
      padding-top: 10px;
      padding-bottom: 15px;
      margin: 0;
      font-family: "Quicksand SemiBold";
      text-align: center;
      font-size: 18px;
      color: white;
    }
  }
}
.bt-register {
  width: 100%;
  border: none;
  font-size: 17px;
  height: 41px;
  background: #02959c;
  color: #fff;
  font-family: "Quicksand Semibold" !important;
  text-transform: uppercase;
}
@media (max-width: 1000px)
{
  .container-footer .frame-f .footer-form p{
    display: block;
    text-align: start
  }
  .container-footer .frame-f .footer-logo .ft-sub{
     display: block;
    text-align: start
  }
  .container-footer .frame-f .footer-logo .social-single{
    margin-left: 0;
  }
  .container-footer .frame-f .footer-logo img{
    display: block;
    text-align: start
  }
  .frame-f .container .row{
    display: inline-block;
    width: 97%;
  }
}
</style>
