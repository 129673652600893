<template>
  <div id="app">
    <navbar />
    
    <router-view></router-view>
    <direction/>

  </div>
</template>

<script>
import navbar from "./view/Home/components/navbar-obaby.vue";

import feedback from "./view/Home/components/feedback-obaby.vue";
import handbook from "./view/Home/components/handbook-obaby.vue";

import direction from "./view/Physical/components/direction-bmi.vue"
export default {
  name: "App",
  components: {
    navbar,
    //feedback,
    // handbook,
    direction,
    
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  color: #2c3e50;
  max-width: 100% !important;
  /* margin-top: 60px; */
}
@import "~bootstrap/dist/css/bootstrap.css";
@import "./assets/style.css";
</style>
