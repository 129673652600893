import axios from 'axios'

const service = axios.create({
    baseURL: "https://icpc1hn.work/DTP/", // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000, // request timeout
    headers:{
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })

export default service