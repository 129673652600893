import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import home from "../view/Home/index.vue";
import local from "../view/Local/index.vue";
import physical from "../view/Physical/index.vue";
import NotFound from "../view/ErrorPage/NotFound";
export const constantRoutes = [
  {
    path: "/",
    component: home,
  },
  {
    path: "/bmi",
    component: physical,
  },
  {
    path: "/diem-ban",
    component: local,
  },
  {
    path: "/blogs",
    component: () => import("../view/Blog/index.vue"),
  },
  {
    path: "/dat-hang/:link",
    component: () => import("../view/Order/index.vue"),
  },
  {
    path: "/:id",
    component: () => import("../view/Blog/components/post-info.vue"),
    meta: {
      reload: true,
    },
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
