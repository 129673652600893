<template>
  <div class="intro-company">
    <div class="container">
      <p class="title-company">Công ty sản xuất uy tín</p>
      <div class="frame-content">
        <div class="line-content"></div>
        <img src="@/assets/img/obb-icon-w.png" alt="" />
        <div class="line-content"></div>
      </div>
      <p class="sub-title">
        Obaby là sản phẩm của công ty dược phẩm CPC1 Hà nội, doanh nghiệp khoa
        học công nghệ duy nhất của ngành dược phía Bắc với đầy đủ các tiêu chuẩn
        GMP, GSP, GDP của Bộ Y tế
      </p>
    </div>
    <div style="max-width: 900px; margin: 0 auto 30px">
      <swiper
        :slides-per-view="3"
        :space-between="30"
        :loop="true"
        :autoplay="{
          delay: 2000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide><img src="@/assets/img/4-1.jpg" alt="" /></swiper-slide>
        <swiper-slide><img src="@/assets/img/5-1.jpg" alt="" /></swiper-slide>
        <swiper-slide><img src="@/assets/img/6-1.png" alt="" /></swiper-slide>
        <swiper-slide><img src="@/assets/img/1-1.png" alt="" /></swiper-slide>
        <swiper-slide><img src="@/assets/img/2-1.png" alt="" /></swiper-slide>
        <swiper-slide><img src="@/assets/img/3-1.png" alt="" /></swiper-slide>
      </swiper>
    </div>
    <div class="container row">
      <div class="col-1">
        <p class="title-col">
          <i class="fa-solid fa-ranking-star"></i>
          Nhà máy tiêu chuẩn GMP-WHO
        </p>
        <p style="text-align: justify">
          Nhà máy sản xuất của công ty rộng 30.000 mét vuông đặt tại Cụm Công
          nghiệp Hà Bình Phương, Thường Tín, Hà Nội. Nhà máy đạt đủ tiêu chuẩn
          GMP-WHO, GSP cho thuốc tiêm, GMP Thực phẩm chức năng mới v..v..
        </p>
      </div>
      <div class="col-1">
        <p class="title-col">
          <i class="fa-solid fa-gear"></i>
          Dây chuyền nhập khẩu khép kín
        </p>
        <p style="text-align: justify">
          Dây chuyền sản xuất hiện đại khép kín được nhập khẩu trực tiếp từ Châu
          Âu, dây chuyền BFS nhập khẩu từ Mỹ... mang đến các sản phẩm đạt chuẩn
          quốc tế, an toàn và hiệu quả cho người sử dụng.
        </p>
      </div>
      <div class="col-1">
        <p class="title-col">
          <i class="fa-solid fa-check"></i>
          Sản phẩm đạt chuẩn quốc tế
        </p>
        <p style="text-align: justify">
          Với hàng trăm sản phẩm đang được lưu hành trong nước và xuất khẩu ra
          thị trường quốc tế, CPC1 Hà Nội tự tin mang đến những gì tốt nhất để
          chăm sóc sức khỏe của bạn và gia đình của mình.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
SwiperCore.use([Autoplay]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped>
.intro-company {
  background: #02959c;
  background-image: url("@/assets/img/bg-blue.png");
  background-position: center;
  // height: 1000px;
  .title-company {
    font-family: Soup;
    font-size: 43px;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 80px;
    color: white;
  }
  .sub-title {
    max-width: 700px;
    margin: 30px auto 40px;
    text-align: justify;
    text-align-last: center;
    font-family: "Quicksand SemiBold";
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
  }
  .frame-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    img {
      height: 35px;
      width: 35px;
      display: inline-block;
      vertical-align: text-top;
      margin: auto 16px;
    }
    .line-content {
      //   right: 52%;
      margin-top: 15px;
      width: 60px;
      height: 4px;
      background-color: #ffffff;
    }
  }
  .row {
    width: 100%;
    .col-1 {
      margin-top: 20px;
      width: 33.3333%;
      margin-bottom: 100px;
      .title-col {
        margin: 0;
        font-size: 18px;
        line-height: 20px;
        color: white;
        font-family: "Quicksand SemiBold";
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        color: #ffffff;
        i {
          font-size: 24px;
        }
      }
    }
  }
}
.swiper-slide img {
  height: 200px;
}
@media only screen and (max-width: 991px) {
  .intro-company {
    .title-company {
      font-size: 33px;
    }
    .sub-title {
      font-size: 16px;
    }
    .frame-content {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      img {
        height: 35px;
        width: 35px;
        display: inline-block;
        vertical-align: text-top;
        margin: auto 16px;
      }
      .line-content {
        //   right: 52%;
        margin-top: 15px;
        width: 60px;
        height: 4px;
        background-color: #ffffff;
      }
    }
    .row {
      width: 100%;
      .col-1 {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 10px;
        .title-col {
          text-align: left;
        }
        p {
          color: #ffffff;

          i {
            font-size: 24px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 550px){
    .intro-company .sub-title{
      width: 88%;
    }
    .intro-company{
      height: 1140px;
    }
    .intro-company .row .col-1 p{
      font-size: 15px;
    }
    .intro-company .row .col-1{
      margin-top: 5px;
    }
}
@media only screen and (max-width: 420px)
{
  .intro-company .title-company{
  font-size: 30px;
  }
}
</style>
