<template>
  <div class="local-modal">
    <div class="modal-content">
      <span class="close" @click="btClose">×</span>
      <div class="local-right">
        <p class="local-lead">Điểm bán tại {{ dataDistrict.district }}</p>
        <p class="local-title">Bạn có thể mua trực tiếp sản phẩm Obaby tại:</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="isShow">STT</th>
              <th scope="col">TÊN NHÀ THUỐC</th>
              <th scope="col" class="isShow">ĐỊA CHỈ</th>
              <th scope="col" class="isShow">QUẬN/HUYỆN</th>
              <th scope="col" class="phone">ĐỊA CHỈ</th>
              <th scope="col">SĐT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dataShopLst" :key="index">
              <td class="isShow" >{{ index + 1 }}</td>
              <td>{{ item.PlaceName }}</td>
              <td class="isShow">
                {{ item.Address }}
              </td>
              <td class="isShow">
                {{ item.District }}
              </td>
              <td class="phone">
                {{ item.Address }}, {{ item.District }}
              </td>
              <td>
                <a :href="'tel:' + item.PhoneNumber">{{ item.PhoneNumber }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      style="
        position: fixed;
        z-index: 100;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.4);
      "
      @click="btClose"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    dataDistrict: Object,
    dataShopLst: Array,
  },
  data() {
    return {
      isShowHidelocalModal: false,
      dataModallocalLst: [
        {
          stt: "1",
          shop: "Nhà thuốc Tý Hương",
          addess: "Tầng 1 Nhà 3 chợ Hà Đông",
          district: "Hà nội",
          sdt: "0397613784",
        },
        {
          stt: "1",
          shop: "Nhà thuốc Tuấn Hà",
          addess: "Hà nội",
          district: "Hà nội",
          sdt: "0397613784",
        },
        {
          stt: "1",
          shop: "Quầy thuốc số 9",
          addess: "Hà nội",
          district: "Hà nội",
          sdt: "0397613784",
        },
      ],
    };
  },
  methods: {
    btClose() {
      this.$emit("btClose", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.local-modal {
  position: fixed;
  z-index: 100;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  .modal-content {
    z-index: 101;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    background-image: url("@/assets/img/bg-buy.jpg");
    background-position: center;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 1200px !important;

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      color: white;
      cursor: pointer;
      width: 16px;
      font-size: 28px;
      font-weight: bold;
    }

    .local-right {
      width: 85%;
      margin: auto;
      text-align: center;
    //   background: #fff !important;
      .local-lead {
        font-family: "Quicksand Bold";
        font-size: 33px;
        color: white;
        text-transform: uppercase;
        text-shadow: 2px 2px 10px #445;
        margin: 30px;
      }
      .local-title {
        font-family: "Quicksand Semibold";
        font-size: 18px;
        color: white;
        text-align: left;
        text-shadow: 2px 2px 10px #445;
        margin: 24px 0;
      }
      .table {
        background: rgba(0, 0, 0, 0.35) !important;
        color: #ffffff;
        thead {
          border: 2px solid #fff;
        }
        tbody {
          border: 2px solid #fff;
        }
        th {
          border: 2px solid #fff;
        }
        td {
          border: 2px solid #fff;
          a {
            color: #ffffff;
            text-decoration: none;
            font-family: "Quicksand Bold";
          }
        }
      }

      .bt-register {
        text-transform: uppercase;
        border: none;
        background: #02959c;
        color: #fff;
        font-family: "Quicksand Bold" !important;
        font-size: 20px;
        margin-top: 20px;
        width: 100%;
        height: 44px;
        padding: 12px auto;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .local-modal {
    .modal-content {
      display: block;
      .close {
        top: 20px;
        right: 20px;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  
    .phone{
    display: none
  }
  }
@media only screen and (max-width: 600px) {
  .local-modal {
    .modal-content {
      display: block;
      .close {
        top: 6px;
        right: 16px;
      }
      .local-right {
        width: 100% !important;
        padding: 0 3px;
        margin-top: -30px;
        .local-lead {
          font-size: 23px;
          margin: 10px;
          margin-top: 40px;
        }
        .local-title {
          padding-left: 20px;
        }

        .table {
          color: #fff;
          .isShow{
            display: none
          }
          thead {
            border: 2px solid #fff;
          }
          tbody {
            border: 2px solid #fff;
          }
          th {
            border: 2px solid #fff;
          }
          td {
            border: 2px solid #fff;
            padding: 1px;
          }
        }
      }
    }
  }
}
</style>
