<template>
  <div class="container-handbook">
    <p class="title-handbook">Cẩm nang mẹ và bé</p>
    <div class="frame-content">
      <div class="line-content"></div>
      <img src="@/assets/img/obb-icon.png" alt="" />
      <div class="line-content"></div>
    </div>

    <div class="container lst-post mt-5 row" id="handbook-obaby">
      <div class="col-lg-4 col-md-5 col-sm-12 hb-post p-2" id="items-large">
        <div class="hb-img" @click="btnPushInfor(firstPost)">
          <img :src="firstPost.LinkImage" alt="" style="width: 100%" />
        </div>
        <div class="hb-content-large">
          <p class="hb-title" @click="btnPushInfor(firstPost)" style="cursor: pointer;">
            {{ firstPost.WebTitle }}
          </p>
          <p
            class="hb-sub-large"
            v-html="sanitizeDescription(firstPost.Description)"
          ></p>

          <a class="hb-bt-large" @click="btnPushInfor(firstPost)"
            >Xem bài viết<i
              class="fa-solid fa-arrow-right"
              style="margin-left: 10px"
            ></i>
          </a>
        </div>
      </div>

      <div
        class="col-lg-8 col-md-7 col-sm-12 hb-post p-2"
        id="items-small"
        style=""
      >
        <div
          class="row pb-2"
          id="row-element"
          v-for="(item, index) in postLst"
          :key="index"
        >
          <div
            class="hb-img col-lg-4 col-md col-sm"
            id="items-img-small"
            @click="btnPushInfor(item)"
          >
            <img :src="item.LinkImage" alt="" style="max-width: 100%" />
          </div>
          <div
            class="col-lg-8 col-md col-sm hb-content"
            id="items-descrip-infor"
            style="padding-left: 8px"
          >
            <p class="hb-title" @click="btnPushInfor(item)">
              {{ item.WebTitle }}
            </p>
            <p
              class="hb-sub"
              v-html="sanitizeDescription(item.Description)"
            ></p>

            <a class="hb-bt" @click="btnPushInfor(item)"
              >Xem bài viết<i
                class="fa-solid fa-arrow-right"
                style="margin-left: 10px"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="b-button">
      <a href="/blogs">Xem đầy đủ </a>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Cookies from "js-cookie";
import { GetWebPostsLst } from "@/api/place";
export default {
  data() {
    return {
      postLst: [
        {
          img: "",
          title:
            "Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở, Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở",
          sub: "Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á.",
        },
        {
          img: "",
          title:
            "Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở",
          sub: "Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á.",
        },
        {
          img: "",
          title:
            "Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở, Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở",
          sub: "Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á.",
        },
        {
          img: "",
          title:
            "Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở, Vị đầu bếp từng nấu ăn cho công nương Diana muốn cả đời sống ở Việt Nam vì trót yêu phở",
          sub: "Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á. Trong hành trình ấy, tại London, Manuel còn vinh dự trở thành người nấu ăn cho công nương Diana, vua Tây Ban Nha cũng như các ca sĩ, diễn viên nổi tiếng khác. Thế nhưng, vì ước mơ được đi tìm tất cả hương vị ẩm thực trên thế giới, ông lại tiếp tục lên đường sang làm việc ở châu Á.",
        },
      ],
      firstPost: {},
      titleWeb: "",
    };
  },
  methods: {
    formatData() {
      this.postLst = this.postLst.map((p) => {
        var a = p.title.split(" ");
        var title = "";
        if (a.length > 40) {
          for (var i = 0; i < 41; i++) {
            title += a[i] + " ";
            if (i == 40) {
              title += "...";
            }
          }
        } else {
          title = p.title;
        }

        var b = p.sub.split(" ");
        var sub = "";
        if (b.length > 150) {
          for (var y = 0; y < 151; y++) {
            sub += b[y] + " ";
            if (i == 150) {
              sub += "...";
            }
          }
        } else {
          sub = p.sub;
        }

        return {
          ...p,
          Title: title,
          Sub: sub,
        };
      });
      this.firstPost = this.postLst[0];
      this.postLst = this.postLst.filter((p) => p != this.firstPost);
    },
    sanitizeDescription(description) {
      // Sử dụng regex để loại bỏ các thẻ iframe
      description = description.replace(/<iframe\b[^>]*>(.*?)<\/iframe>/gi, "");

      // Sử dụng regex để chuyển thẻ <em> thành <p>

      return description;
    },
    // ...mapMutations(["updatePostID"]),
    btnPushInfor(item) {
      //  this.updatePostID(item.RowID);
      Cookies.set("value", item.RowID);
      function convertWebTitlle(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(
          /!|@|%|\^|\*|\(|\)|\+|\?|\/|,|\.|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
          " "
        );
        str = str.replace(/ /g, "-");
        return str;
      }
      this.titleWeb = convertWebTitlle(item.WebTitle);
      this.$router.push(this.titleWeb);
    },
    getWebPostsLst() {
      GetWebPostsLst({ ProductID: "Obaby" }).then((res) => {
        this.postLst = res.data.WebPostsLst.filter((p) => p.Status == 1).map(
          (item, index) => {
            return {
              ...item,
              LinkImage:
                "https://icpc1hn.work/DTP/File/DownloadImageWebPosts?UserName=&Token=&RowID=" +
                item.RowID,
              Key: index + 1,
            };
          }
        );
        // this.postLst = this.postLst
        //   .map((x) => ({ ...x, r: Math.random() }))
        //   .sort((a, b) => a.r - b.r)
        //   .slice(0, 4);
        // this.firstPost = this.postLst[0];
        // this.postLst = this.postLst.filter((p) => p != this.firstPost);
        this.postLst.reverse();
        this.firstPost = this.postLst[0];
        this.postLst = [
          this.postLst[1],
          this.postLst[2],
          this.postLst[3],
          this.postLst[4],
        ];
      });
    },
  },
  created() {
    this.getWebPostsLst();
  },
};
</script>

<style lang="scss">
#items-small {
  width: 64%;
  margin-left: 0;
}
#row-element {
  margin-bottom: 20px;
}
#handbook-obaby {
  width: 80%;
}
.hb-img img {
  cursor: pointer;
  width: 100% !important;
}
#items-img-small img {
  width: 100% !important;
  height: 90%;
  object-fit: cover;
}
.hb-sub-large {
  margin-top: 7px;
  font-family: "Quicksand Regular";
  color: black;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: none;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}

em {
  font-family: "Quicksand Regular";
  font-style: normal;
  line-height: 0 !important;
}

.hb-content-large {
  margin-top: 12px;
  font-size: 21px;
  color: black;
  text-align: left;
  font-family: "Quicksand SemiBold";
}
.hb-bt-large {
  background: #02959c;
  color: white;
  font-family: "Quicksand SemiBold";
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 28px;
  box-shadow: 0px 0px 6px 0px #999;
  cursor: pointer;
}
.hb-bt-large:hover {
  background-color: #e35b89;
  color: white;
}
.container-handbook {
  .title-handbook {
    padding: 70px 0 0px;
    margin-bottom: 10px;
    font-family: Soup;
    font-size: 40px;
    text-align: center;
    color: #02959c;
  }
  .frame-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    img {
      height: 35px;
      width: 35px;
      display: inline-block;
      vertical-align: text-top;
      margin: auto 16px;
    }
    .line-content {
      margin-top: 15px;
      width: 60px;
      height: 4px;
      background-color: #02959c;
    }
  }
  .lst-post {
    .hb-post {
      .hb-content {
        .hb-title {
          font-size: 18px;
          line-height: 24px;
          color: black;
          text-align: left;
          font-family: "Quicksand SemiBold";
          margin-bottom: 12px;
          cursor: pointer;
        }
        .hb-sub {
          margin-top: 7px;
          font-family: "Quicksand Regular";
          color: black;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: none;
          -webkit-display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
        }

        .hb-sub p {
          font-weight: 200;
        }
        .hb-sub > p {
          font-weight: 200;
        }
        .hb-bt {
          cursor: pointer;
          color: #02959c;
          font-family: "Quicksand SemiBold";
          font-size: 16px;
          text-decoration: none;
          border-radius: 28px;
          float: left;
        }
        .hb-bt:hover {
          color: #e35b89;
        }
      }
    }
  }
  .b-button {
    margin-top: 100px;
    margin-bottom: 100px;
    a {
      background: #02959c;
      color: white;
      font-family: "Quicksand SemiBold";
      padding: 18px 72px;
      font-size: 25px;
      border-radius: 28px;
      text-transform: uppercase;
      box-shadow: 0px 0px 6px 0px #999;
      text-decoration: none;
    }
  }
}
.container-handbook .b-button a:hover {
  background-color: #e35b89;
  transition: all 0.15s linear;
}
@media screen and (max-width: 1200px) {
  #handbook-obaby {
    width: 90%;
  }
}
@media (min-width: 555px) and (max-width: 990px) {
  .hb-content-large > a {
    background: #02959c;
    color: white;
    font-family: "Quicksand SemiBold";
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 28px;
    box-shadow: 0px 0px 6px 0px #999;
    text-decoration: none;
  }
}
@media screen and (max-width: 991px) {
  #items-large {
    display: inline-block;
    width: 100%;
  }
  #items-small {
    width: 100% !important;
    margin-left: 0;
  }
  #items-img-small {
    display: inline-block;
  }
  #items-img-small img {
    // max-width: 410px !important;
    // max-height: 310px;
    // object-fit: cover;
  }
  .hb-bt-large {
    cursor: pointer;
    color: #02959c;
    font-family: "Quicksand SemiBold";
    font-size: 16px;
    text-decoration: none;
    border-radius: 28px;
    float: left;
    background: none;
    box-shadow: none;
    padding: 0;
  }
  .hb-content-large .entry-content {
    display: none;
  }
  .hb-bt-large:hover {
    background-color: white;
    color: #e35b89;
  }
  .container-handbook {
    .lst-post {
      .hb-post {
        .hb-content {
          .hb-title {
            font-size: 21px;
            cursor: pointer;
          }
          .hb-sub {
            font-size: 16px;
            margin-bottom: 16px;
          }
          .hb-img {
            max-width: 200px;
          }
        }
      }
    }
    .b-button {
      margin-top: 80px;
      a {
        font-size: 22px;
      }
    }
  }
}
@media (min-width: 575px) {
  #items-descrip-infor {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .container-handbook {
    .lst-post {
      .hb-post {
        .hb-content {
          .hb-title {
            font-size: 21px;
          }
          .hb-sub {
            font-size: 16px;
            margin-bottom: 16px;
            word-break: break-word;
            visibility: visible;
            max-height: 100px;
            line-height: 1;
          }
          .hb-img {
            max-width: 200px;
          }
        }
      }
    }
    .b-button {
      margin-top: 60px;
      margin-bottom: 40px;
      a {
        font-size: 20px;
        padding: 15px 60px;
      }
    }
  }
}
</style>
