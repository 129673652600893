<template>
  <div>
    <buyModal v-if="isShowBuyModal" @btClose="isShowBuyModal = false" />
    <section
      class="bmiform-section"
      id="bmiform"
      style="
        overflow: hidden;
        background-image: url('@/assets/img/bg-bmi-2.jpg');
        margin-bottom: -150px;
      "
    >
      <div class="container-check" style="margin-top: 50px">
        <div class="row-check">
          <p class="bmif-lead">Kiểm tra thể trạng bé</p>
          <div class="under-leading-white-check">
            <div class="ul-wrap-check">
              <div class="center-check">
                <div class="obb-icon">
                  <div class="line-wrap"></div>
                  <img
                    class="lazyloaded-banner"
                    src="@/assets/img/obb-icon-w.png"
                  />
                  <div class="line-wrap"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="bmi-up" style="float: left; width: 100%">
            <form class="bmiform" onsubmit="return false">
              <div class="col-sm-6 bmif-single" id="top-item-check1">
                <label>*Họ tên con: </label>
                <input
                  required
                  type="text"
                  id="name-check"
                  oninvalid="this.setCustomValidity('Nhập tên bé!')"
                  oninput="this.setCustomValidity('')"
                  placeholder="Nhập tên bé"
                  v-model="bmiChildren.NameChidren"
                />
              </div>
              <div class="col-sm-6 bmif-single" id="top-item-check2">
                <label>*Tuổi con:</label>
                <select
                  required
                  id="age"
                  v-model="bmiChildren.AgeChidren"
                  oninvalid="this.setCustomValidity('Chọn tuổi bé!')"
                  oninput="this.setCustomValidity('')"
                >
                  <option value="" disabled selected hidden>
                    Chọn độ tuổi
                  </option>
                  <option value="0-5 tháng">0-5 tháng tuổi</option>
                  <option value="6-11 tháng">6-11 tháng tuổi</option>
                  <option value="12-17 tháng">12-17 tháng tuổi</option>
                  <option value="18-23 tháng">18-23 tháng tuổi</option>
                  <option value="2">2 tuổi</option>
                  <option value="3">3 tuổi</option>
                  <option value="4">4 tuổi</option>
                  <option value="5">5 tuổi</option>
                  <option value="6">6 tuổi</option>
                  <option value="7">7 tuổi</option>
                  <option value="8">8 tuổi</option>
                  <option value="9">9 tuổi</option>
                  <option value="10">10 tuổi</option>
                  <option value="11">11 tuổi</option>
                  <option value="12">12 tuổi</option>
                  <option value="Trên 12">Trên 12 tuổi</option>
                </select>
              </div>

              <div class="col-sm-6 bmif-single" id="top-item-check3">
                <label>*Giới tính:</label>
                <select
                  required
                  id="sex"
                  v-model="bmiChildren.SexChidren"
                  oninvalid="this.setCustomValidity('Chọn giới tính bé!')"
                  oninput="this.setCustomValidity('')"
                >
                  <option value="" disabled selected hidden>
                    Chọn giới tính
                  </option>
                  <option value="Nam">Nam</option>
                  <option value="Nữ">Nữ</option>
                </select>
              </div>
              <div class="col-sm-6 bmif-single" id="top-item-check4">
                <label>*Cân nặng (kg):</label>
                <input
                  required
                  step="any"
                  type="number"
                  id="weight"
                  v-model="bmiChildren.Weight"
                  oninvalid="this.setCustomValidity('Nhập cân nặng bé!')"
                  oninput="this.setCustomValidity('')"
                  placeholder="Nhập tuổi"
                />
              </div>
              <div class="col-sm-6 bmif-single" id="top-item-check5">
                <label>*Chiều cao (cm):</label>
                <input
                  required
                  step="any"
                  type="number"
                  id="height"
                  v-model="bmiChildren.Height"
                  oninvalid="this.setCustomValidity('Nhập chiều cao bé!')"
                  oninput="this.setCustomValidity('')"
                  placeholder="Nhập chiều cao"
                />
              </div>

              <div class="bmif-button">
                <a href="#bmi-banner">
                  <input
                    type="submit"
                    value="Kiểm tra"
                    @click="checkBmiIndex"
                  />
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section
      class="bmiq-evaluate"
      style="background-image: url('@/assets/img/bg-bmi-test.jpg')"
    >
      <div class="container-eveluate" v-if="isShowEvaluate">
        <div class="row-evaluate">
          <div class="kq-evaluate">
            <p class="kq-lead-evaluate">Đánh giá chung</p>
            <div class="under-leading-white-evaluate">
              <div class="ul-wrap-evaluate">
                <div class="center-wrap-evaluate">
                  <div class="obb-icon-evaluate">
                    <div class="line-wrap-evaluate"></div>
                    <img
                      class="lazyloaded-banner"
                      src="@/assets/img/obb-icon.png"
                    />
                    <div class="line-wrap-evaluate"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kq-form-evaluate">
              <div class="blue-wrap-evaluate">
                <p class="kqf-title-evaluate" id="kqf-name">
                  Bé: {{ bmiChildren.NameChidren }}
                </p>
                <p class="kqf-title-evaluate" id="kqf-age">
                  Tuổi bé: {{ bmiChildren.AgeChidren }} tuổi
                </p>
                <p class="kqf-title-evaluate" id="kqf-sex">
                  Giới tính: {{ bmiChildren.SexChidren }}
                </p>
                <p class="kqf-title-evaluate" id="kqf-height">
                  Chiều cao: {{ bmiChildren.Height }} cm
                </p>
                <p class="kqf-title-evaluate" id="kqf-weight">
                  Cân nặng: {{ bmiChildren.Weight }} kg
                </p>
                <p class="kqf-title-evaluate" id="kqf-bmi">
                  BMI: {{ this.resultBmi }}
                </p>
                <p
                  class="kqf-kq"
                  style="display: block; width: 100%"
                  id="kqf-kq"
                >
                  Thể trạng: {{ this.statusPhysical.physicalCondition }}
                </p>
              </div>
              <p class="kqf-lead">Khuyến nghị</p>
              <div class="kqf-advice">
                <div class="single-kq" v-if="kq11">
                  <p>
                    Bé đang bị thiếu cân ở thể suy dinh dưỡng, cần được khám
                    dinh dưỡng sớm để tìm nguyên nhân và có hướng hỗ trợ tốt
                    nhất.
                  </p>
                  <ul>
                    <li>
                      Cần cho trẻ bú mẹ ngay sau khi vừa mới sinh ra đến 24
                      tháng vì sữa mẹ là nguồn thức ăn đầu đời tốt nhất cho trẻ.
                      Ngoài cung cấp chất dinh dưỡng, sữa mẹ còn chứa nhiều
                      kháng thể giúp trẻ chống lại bệnh tật, các bệnh nhiễm
                      trùng nguy hiểm. Lưu ý, những mẹ ít sữa nên ăn uống đầy đủ
                      về lượng và chất để sữa tiết ra nhiều hơn cũng như cung
                      cấp đủ dưỡng chất hơn giúp trẻ phát triển khỏe mạnh.
                      <br />
                      Trường hợp mẹ mất sữa: Mẹ có thể cho trẻ bú sữa công thức,
                      sữa cho trẻ suy dinh dưỡng phù hợp theo nhu cầu và độ tuổi
                      của trẻ.
                    </li>
                    <li>
                      Đảm bảo giấc ngủ cho trẻ: Thông thường, tổng thời gian ngủ
                      trong một ngày của trẻ sơ sinh là từ 16 tiếng trở lên, tuy
                      nhiên chúng thường được chia thành nhiều lần và mỗi giấc
                      ngủ khá ngắn, chỉ kéo dài vài giờ. Giấc ngủ ban đầu có thể
                      rất thất thường, song lịch trình ngủ sẽ đi vào quỹ đạo ổn
                      định với thời lượng lâu hơn khi bé dần trưởng thành. Bé
                      càng lớn đồng nghĩa với lượng sữa bú được càng nhiều và sẽ
                      no lâu hơn, do đó trẻ sơ sinh không cần phải thức dậy giữa
                      những giấc ngủ ngắn vì lý do đói bụng.
                    </li>
                    <li>
                      Khoảng 2 giờ đồng hồ cho trẻ ăn một lần. Lượng thức ăn có
                      thể tăng dần từ ít đến nhiều, ăn từ loãng đến đặc. Không
                      chỉ tăng dần lượng calo, các bữa ăn của trẻ sẽ tăng cả về
                      lượng protein, cụ thể từ 1 - 2 - 3 - 4 - 5 g/ kg. Khi trẻ
                      đã tăng trưởng ổn định, mẹ có thể duy trì mức mức 3g
                      protein/ kg.
                    </li>
                    <li>Tăng thêm lượng dầu mỡ trong khẩu phần ăn của trẻ.</li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Cần thiết cho bé sử dụng thêm sản phẩm hỗ trợ như: Sử dụng
                    Obaby (trên 2 tuổi) / Obaby123 (dưới 2 tuổi) để giúp con ăn
                    uống ngon miệng, tăng cường chuyển hóa hấp thu thức ăn tốt
                    hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO - D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq12">
                  <p>
                    Bé đang bị thiếu cân ở thể suy dinh dưỡng, cần được khám
                    dinh dưỡng sớm để tìm nguyên nhân và có hướng hỗ trợ tốt
                    nhất.
                  </p>
                  <ul>
                    <li>
                      Khi bắt đầu cho bé ăn dặm mẹ nên chú ý cần phải cho trẻ
                      được làm quen với cách ăn và số lượng ăn. Cho trẻ ăn 1 đến
                      2 muỗng thức ăn và theo dõi các dấu hiệu của trẻ. Sau khi
                      trẻ bắt đầu quen với quá trình ăn dặm, lúc này sẽ tăng dần
                      số lượng thực phẩm trong bữa ăn của trẻ.
                    </li>
                    <li>
                      Lưu ý khi cho trẻ ăn dặm cần tuân thủ nguyên tắc cho ăn từ
                      ít đến nhiều, từ loãng đến đặc
                    </li>
                    <li>Các bữa ăn nên cách nhau 2-3 tiếng</li>
                    <li>
                      Bữa ăn cần đầy đủ các chất dinh dưỡng( chất đạm, tinh bột,
                      dầu béo, vitamin)
                    </li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Cần thiết cho bé sử dụng thêm sản phẩm hỗ trợ như: Sử dụng
                    Obaby (trên 2 tuổi) / Obaby123 (dưới 2 tuổi) để giúp con ăn
                    uống ngon miệng, tăng cường chuyển hóa hấp thu thức ăn tốt
                    hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO - D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq13">
                  <p>
                    Bé đang bị thiếu cân ở thể suy dinh dưỡng, cần được khám
                    dinh dưỡng sớm để tìm nguyên nhân và có hướng hỗ trợ tốt
                    nhất.
                  </p>
                  <ul>
                    <li>
                      Trong độ tuổi này, những chiếc răng sữa đã xuất hiện, bé
                      có thể chuyển sang thức ăn dạng đặc để tập nhai, đồng thời
                      làm đa dạng hóa thức ăn hấp thu vào cơ thể. Các mẹ cũng
                      bắt đầu đưa bé vào “khuôn khổ”: ăn đúng giờ, đúng bữa,
                      không vừa ăn vừa chơi hoặc xem ti vi…
                      <br />
                      Một điều cần chú ý là các mẹ không nên ép con ăn bằng mọi
                      cách hoặc cho con đi ăn rong vì như vậy ảnh hưởng đến tâm
                      lý và sức khỏe dạ dày của bé.
                    </li>
                    <li>Ăn nhiều bữa trong ngày nhằm tăng dần calo</li>
                    <li>
                      Khoảng 2 giờ đồng hồ cho trẻ ăn một lần. Không chỉ tăng
                      dần lượng calo, các bữa ăn của trẻ sẽ tăng cả về lượng
                      protein, cụ thể từ 1 - 2 - 3 - 4 - 5 g/ kg. Khi trẻ đã
                      tăng trưởng ổn định, mẹ có thể duy trì mức mức 3g protein/
                      kg.
                    </li>
                    <li>Tăng thêm lượng dầu mỡ trong khẩu phần ăn của trẻ</li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Cần thiết cho bé sử dụng thêm sản phẩm hỗ trợ như: Sử dụng
                    Obaby (trên 2 tuổi) / Obaby123 (dưới 2 tuổi) để giúp con ăn
                    uống ngon miệng, tăng cường chuyển hóa hấp thu thức ăn tốt
                    hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq14">
                  <p>
                    Bé đang bị thiếu cân ở thể suy dinh dưỡng, cần được khám
                    dinh dưỡng sớm để tìm nguyên nhân và có hướng hỗ trợ tốt
                    nhất.
                  </p>
                  <ul>
                    <li>
                      Với trẻ bị suy dinh dưỡng, nên cho trẻ ăn nhiều bữa trong
                      ngày, mỗi bữa một ít để bảo đảm số lượng thức ăn cần thiết
                      cho trẻ, đồng thời phải cung cấp năng lượng cao hơn trẻ
                      bình thường. Chế độ ăn của trẻ nên được cân đối giữa các
                      nhóm chất:
                      <br />
                      <b>Tăng lượng protein:</b> Suy dinh dưỡng ở trẻ em cần
                      phải tăng lượng protein hơn nhu cầu bình thường để nhanh
                      chóng phục hồi tình trạng dinh dưỡng của trẻ. Nguyên tắc
                      xây dựng chế độ ăn: Tăng dần lượng calo/kg từ 90-150
                      Kcalo/kg/ngày, và tăng dần lượng protein từ 2g/kg lên 5-7
                      g/kg/ngày. Nên dùng các loại thực phẩm có nguồn gốc động
                      vật như: trứng, thịt, sữa, cá, tôm, cua… hoặc có thể dùng
                      các protein có nguồn gốc thực vật như đậu, đỗ, lạc, vừng.
                      <br />
                      <b>Tăng dầu mỡ:</b> Dầu mỡ cung cấp năng lượng cho trẻ gấp
                      đôi chất bột và chất đạm. Vì vậy, trong bữa ăn hàng ngày
                      của trẻ bị suy dinh dưỡng, mẹ nên tăng lượng dầu mỡ.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Cần thiết cho bé sử dụng thêm sản phẩm hỗ trợ như: Sử dụng
                    Obaby (trên 2 tuổi) / Obaby123 (dưới 2 tuổi) để giúp con ăn
                    uống ngon miệng, tăng cường chuyển hóa hấp thu thức ăn tốt
                    hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq15">
                  <p>
                    Bé đang bị thiếu cân ở thể suy dinh dưỡng, cần được khám
                    dinh dưỡng sớm để tìm nguyên nhân và có hướng hỗ trợ tốt
                    nhất.
                  </p>
                  <ul>
                    <li>
                      Để cải thiện tình trạng suy dinh dưỡng giai đoạn này bố mẹ
                      cần chú ý cả về tâm lý và dinh dưỡng
                    </li>
                    <li>
                      <b>Tâm lý:</b> Trẻ gặp nhiều vấn đề về tâm lý do thay đổi
                      môi trường học tập, thầy cô, bạn bè…khiến trẻ hụt hẫng,
                      rối loạn lo âu khiến trẻ chán ă, Lâu dần dẫn đến suy dinh
                      dưỡng. Do đó cha mẹ nên quan tâm chia sẻ với trẻ để có
                      hướng khắc phục kịp thời
                    </li>
                    <li>
                      <b>Dinh dưỡng:</b>
                      <br />
                      + Nên có bữa phụ như sữa, bánh mỳ.,…
                      <br />
                      + Trang trí món ăn khiến trẻ thích thú
                      <br />
                      + Trước khi chế biến món ăn nên hỏi ý kiến trẻ để trẻ biết
                      mình được tôn trọng và thích thú với bữa ăn hơn
                      <br />
                      + Cần cho trẻ ăn đa dạng, đủ 4 nhóm thực phẩm (tinh bột,
                      chất đạm, chất béo, vitamin) trong các bữa ăn chính, chú ý
                      các thực phẩm giàu đạm, sắt, kẽm như: trứng, thịt, cá,
                      tôm,
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Cha mẹ có thể cho bé sử dụng Obaby để giúp con ăn uống ngon
                    miệng, hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch)).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-2-1" v-if="kq21">
                  <p>
                    Bé đang phát triển bình thường, tuy nhiên có hơi nhẹ cân so
                    với chuẩn.
                  </p>
                  <ul>
                    <li>
                      Đảm bảo nguồn sữa mẹ đầy đủ cả và lượng cũng như chất
                      lượng. Cho con bú đúng cách hoặc lựa chọn sữa công thức
                      phù hợp với trẻ
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq22">
                  <p>
                    Bé đang phát triển bình thường, tuy nhiên có hơi nhẹ cân so
                    với chuẩn.
                  </p>
                  <ul>
                    <li>
                      Cần cho trẻ ăn đa dạng, đủ 4 nhóm thực phẩm (tinh bột,
                      chất đạm, chất béo, vitamin) trong các bữa ăn chính, chú ý
                      các thực phẩm giàu đạm, sắt, kẽm như: trứng, thịt, cá,
                      tôm, cua,lươn, ếch….
                    </li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Tạo lập lịch ăn đúng giờ theo các bữa trong ngày ở đúng vị
                      trí ăn (bàn ăn, ghế ăn) không đi ăn rong, hạn chế việc sử
                      dụng thiết bị công nghệ hoặc đồ chơi trong bữa ăn
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby123 để giúp con ăn uống ngon
                    miệng, hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq23">
                  <p>
                    Bé đang phát triển bình thường, tuy nhiên có hơi nhẹ cân so
                    với chuẩn.
                  </p>
                  <ul>
                    <li>
                      Cần cho trẻ ăn đa dạng, đủ 4 nhóm thực phẩm (tinh bột,
                      chất đạm, chất béo, vitamin) trong các bữa ăn chính, chú ý
                      các thực phẩm giàu đạm, sắt, kẽm như: trứng, thịt, cá,
                      tôm, cua,lươn, ếch….
                    </li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Tạo lập lịch ăn đúng giờ theo các bữa trong ngày ở đúng vị
                      trí ăn (bàn ăn, ghế ăn) không đi ăn rong, hạn chế việc sử
                      dụng thiết bị công nghệ hoặc đồ chơi trong bữa ăn
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby (trên 2 tuổi) / Obaby123 (dưới
                    2 tuổi) để giúp con ăn uống ngon miệng, hấp thu thức ăn tốt
                    hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq24">
                  <p>
                    Bé đang phát triển bình thường, tuy nhiên có hơi nhẹ cân so
                    với chuẩn.
                  </p>
                  <ul>
                    <li>
                      Cần cho trẻ ăn đa dạng, đủ 4 nhóm thực phẩm (tinh bột,
                      chất đạm, chất béo, vitamin) trong các bữa ăn chính, chú ý
                      các thực phẩm giàu đạm, sắt, kẽm như: trứng, thịt, cá,
                      tôm, cua,lươn, ếch….
                    </li>
                    <li>
                      Cần tạo sự hấp dẫn, ngon miệng và vui vẻ khi ăn, không làm
                      trẻ hoảng sợ khi ăn.
                    </li>
                    <li>
                      Tạo lập lịch ăn đúng giờ theo các bữa trong ngày ở đúng vị
                      trí ăn (bàn ăn, ghế ăn) không đi ăn rong, hạn chế việc sử
                      dụng thiết bị công nghệ hoặc đồ chơi trong bữa ăn
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq25">
                  <p>
                    Bé đang phát triển bình thường, tuy nhiên có hơi nhẹ cân so
                    với chuẩn.
                  </p>
                  <ul>
                    <li>
                      Trong độ tuổi từ 6 – 12 tuổi, trẻ đang học tiểu học và bắt
                      đầu bước vào giai đoạn tiền dậy thì, thậm chí có bé đã bắt
                      đầu dậy thì. Trẻ cần khoảng 1.350 – 2.200 kcal/ngày,
                      thường có nhu cầu ăn nhiều loại thức ăn hơn trước. Các bé
                      thường ăn 4 – 5 lần/ngày, bao gồm cả bữa ăn nhẹ
                    </li>
                    <li>
                      Mỗi ngày trẻ 6 – 12 tuổi cần uống trung bình từ 1.300 –
                      1.500ml bao gồm cả nước, sữa và nước trái cây, tương đương
                      với 6 – 8 ly nước mỗi ngày để cơ thể khỏe mạnh và chuyển
                      hóa tốt.
                    </li>
                    <li>
                      Hạn chế cho trẻ uống các loại nước có ga, nước ngọt, nước
                      giải khát có nhiều đường vì chúng giàu năng lượng nhưng
                      lại có hàm lượng dinh dưỡng rất thấp làm tăng nguy cơ béo
                      phì ở trẻ.
                    </li>
                    <li>
                      Ngoài chế độ ăn uống cân bằng, khoa học, bé cần hoạt động
                      thể chất ít nhất 1 giờ mỗi ngày để có chế độ sinh hoạt
                      lành mạnh. Thời gian tập luyện có thể chia thành nhiều lần
                      trong ngày. Bố mẹ nên khuyến khích con chạy nhảy, đá bóng,
                      chơi đuổi bắt, nhảy dây, đá cầu,... nhằm tăng khả năng dẻo
                      dai, tốc độ, phản ứng linh hoạt cùng khả năng phối hợp.
                    </li>
                    <li>
                      Khuyến khích trẻ vận động, chạy nhảy ngoài trời. Tiêm
                      chủng đúng lịch.
                    </li>
                    <li>
                      Theo dõi cân nặng và chiều cao của trẻ hàng tháng theo
                      biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" v-if="kq31">
                  <p>
                    Bé đang phát triển bình thường, các chỉ số đúng tiêu chuẩn
                    của trẻ.
                  </p>
                  <ul>
                    <li>
                      Duy trì cho trẻ bú mẹ hoàn toàn đến khi 6 tháng tuổi, khi
                      trẻ đủ 6 tháng tuổi, ngoài sữa mẹ trẻ cần bổ sung các bữa
                      ăn dặm hàng ngày
                    </li>
                    <li>
                      Tiêm chủng đúng lịch. Theo dõi cân nặng và chiều cao của
                      trẻ hàng tháng theo biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-3-2" v-if="kq32">
                  <p>
                    Bé đang phát triển bình thường, các chỉ số đúng tiêu chuẩn
                    của trẻ.
                  </p>
                  <ul>
                    <li>
                      Cần duy trì cho trẻ ăn uống đa dạng, đủ 4 nhóm thực phẩm
                      (tinh bột, chất đạm, chất béo, vitamin) trong mỗi bữa ăn
                      chính.
                    </li>
                    <li>
                      Để có thói quen ăn uống tốt cho con, hãy rèn luyện hàng
                      ngày: Ăn uống đúng giờ, đúng vị trí ăn uống (bàn ăn, ghế
                      ăn), không đi ăn rong, hạn chế tối đa sử dụng đồ chơi (đặc
                      biệt là đồ chơi công nghệ) trong giờ ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời.
                    </li>
                    <li>
                      Tiêm chủng đúng lịch. Theo dõi cân nặng và chiều cao của
                      trẻ hàng tháng theo biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-3-3" v-if="kq33">
                  <p>
                    Bé đang phát triển bình thường, các chỉ số đúng tiêu chuẩn
                    của trẻ.
                  </p>
                  <ul>
                    <li>
                      Cần duy trì cho trẻ ăn uống đa dạng, đủ 4 nhóm thực phẩm
                      (tinh bột, chất đạm, chất béo, vitamin) trong mỗi bữa ăn
                      chính.
                    </li>
                    <li>
                      Để có thói quen ăn uống tốt cho con, hãy rèn luyện hàng
                      ngày: Ăn uống đúng giờ, đúng vị trí ăn uống (bàn ăn, ghế
                      ăn), không đi ăn rong, hạn chế tối đa sử dụng đồ chơi (đặc
                      biệt là đồ chơi công nghệ) trong giờ ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời.
                    </li>
                    <li>
                      Tiêm chủng đúng lịch. Theo dõi cân nặng và chiều cao của
                      trẻ hàng tháng theo biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-3-4" v-if="kq34">
                  <p>
                    Bé đang phát triển bình thường, các chỉ số đúng tiêu chuẩn
                    của trẻ.
                  </p>
                  <ul>
                    <li>
                      Cần duy trì cho trẻ ăn uống đa dạng, đủ 4 nhóm thực phẩm
                      (tinh bột, chất đạm, chất béo, vitamin) trong mỗi bữa ăn
                      chính.
                    </li>
                    <li>
                      Để có thói quen ăn uống tốt cho con, hãy rèn luyện hàng
                      ngày: Ăn uống đúng giờ, đúng vị trí ăn uống (bàn ăn, ghế
                      ăn), không đi ăn rong, hạn chế tối đa sử dụng đồ chơi (đặc
                      biệt là đồ chơi công nghệ) trong giờ ăn.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời.
                    </li>
                    <li>
                      Tiêm chủng đúng lịch. Theo dõi cân nặng và chiều cao của
                      trẻ hàng tháng theo biểu đồ tăng trưởng.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-3-5" v-if="kq35">
                  <p>
                    Bé đang phát triển bình thường, các chỉ số đúng tiêu chuẩn
                    của trẻ.
                  </p>
                  <ul>
                    <li>
                      Cần duy trì cho trẻ ăn uống đa dạng, đủ 4 nhóm thực phẩm
                      (tinh bột, chất đạm, chất béo, vitamin) trong mỗi bữa ăn
                      chính.
                    </li>
                    <li>
                      Ngoài các bữa chính trẻ cần ăn thêm các bữa phụ xen kẽ
                      nhằm đáp ứng nhu cầu dinh dưỡng tăng cao để trẻ phát triển
                      tốt
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Có thể hỗ trợ sử dụng Obaby để giúp con ăn uống ngon miệng,
                    hấp thu thức ăn tốt hơn và tăng cường hệ miễn dịch).
                  </p>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-4-3" v-if="kq43">
                  <p>
                    Bé đang bị có cân nặng nhỉnh hơn so với chiều cao hiện có,
                    nên điều chỉnh chế độ dinh dưỡng phù hợp.
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Giảm ăn đồ béo, ngọt (bánh kẹo, snack, nước ngọt, dầu, mỡ,
                      bơ, nước hầm xương…).
                    </li>
                    <li>
                      Tăng rau/củ, trái cây ít ngọt. Tập ăn thịt cá nạc, ăn cá
                      nhiều hơn thịt, chú ý các thực phẩm giàu Canxi, Sắt, Kẽm
                      (sữa, trứng, thịt, cá, tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-4-4" v-if="kq44">
                  <p>
                    Bé đang bị có cân nặng nhỉnh hơn so với chiều cao hiện có,
                    nên điều chỉnh chế độ dinh dưỡng phù hợp.
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Giảm ăn đồ béo, ngọt (bánh kẹo, snack, nước ngọt, dầu, mỡ,
                      bơ, nước hầm xương…).
                    </li>
                    <li>
                      Tăng rau/củ, trái cây ít ngọt. Tập ăn thịt cá nạc, ăn cá
                      nhiều hơn thịt, chú ý các thực phẩm giàu Canxi, Sắt, Kẽm
                      (sữa, trứng, thịt, cá, tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-4-5" v-if="kq45">
                  <p>
                    Bé đang bị có cân nặng nhỉnh hơn so với chiều cao hiện có,
                    nên điều chỉnh chế độ dinh dưỡng phù hợp.
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Giảm ăn đồ béo, ngọt (bánh kẹo, snack, nước ngọt, dầu, mỡ,
                      bơ, nước hầm xương…).
                    </li>
                    <li>
                      Tăng rau/củ, trái cây ít ngọt. Tập ăn thịt cá nạc, ăn cá
                      nhiều hơn thịt, chú ý các thực phẩm giàu Canxi, Sắt, Kẽm
                      (sữa, trứng, thịt, cá, tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-5-3" v-if="kq53">
                  <p>
                    Bé đang bị dư thừa cân nặng so với chiều cao hiện có, nên
                    điều chỉnh chế độ dinh dưỡng phù hợp
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Không cho trẻ ăn các thức ăn nhiều dầu mỡ, đồ ăn chiên
                      xào, thay vào đó có thể hấp, luộc, cho trẻ ăn ít gia vị và
                      những loại đồ ăn vừa chứa chất béo vừa nhiều đường như
                      bánh ngọt, bánh nướng, khoai tây chiên, xúc xích, kem . Có
                      thể cho trẻ ăn nhiều rau xanh, các loại trái cây .
                    </li>
                    <li>
                      Tập cho trẻ ăn thịt cá nạc, ăn cá nhiều hơn thịt, chú ý
                      các thực phẩm giàu Canxi, Sắt, Kẽm (sữa, trứng, thịt, cá,
                      tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp 3 bánh…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                    <li>
                      Tái khám, theo dõi cân nặng và chiều cao của trẻ hàng
                      tháng để điều chỉnh chế độ ăn và vận động phù hợp.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-5-4" v-if="kq54">
                  <p>
                    Bé đang bị dư thừa cân nặng so với chiều cao hiện có, nên
                    điều chỉnh chế độ dinh dưỡng phù hợp
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Không cho trẻ ăn các thức ăn nhiều dầu mỡ, đồ ăn chiên
                      xào, thay vào đó có thể hấp, luộc, cho trẻ ăn ít gia vị và
                      những loại đồ ăn vừa chứa chất béo vừa nhiều đường như
                      bánh ngọt, bánh nướng, khoai tây chiên, xúc xích, kem . Có
                      thể cho trẻ ăn nhiều rau xanh, các loại trái cây .
                    </li>
                    <li>
                      Tập cho trẻ ăn thịt cá nạc, ăn cá nhiều hơn thịt, chú ý
                      các thực phẩm giàu Canxi, Sắt, Kẽm (sữa, trứng, thịt, cá,
                      tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp 3 bánh…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                    <li>
                      Tái khám, theo dõi cân nặng và chiều cao của trẻ hàng
                      tháng để điều chỉnh chế độ ăn và vận động phù hợp.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="single-kq" id="kq-5-5" v-if="kq55">
                  <p>
                    Bé đang bị dư thừa cân nặng so với chiều cao hiện có, nên
                    điều chỉnh chế độ dinh dưỡng phù hợp
                  </p>
                  <ul>
                    <li>
                      Trẻ cần giảm tốc độ tăng cân nhưng vẫn đảm bảo tăng trưởng
                      chiều cao so với tuổi.
                    </li>
                    <li>
                      Giảm bớt lượng tinh bột từ gạo, bột, bún, phở, mì, nui…
                      trong các bữa ăn so với hiện tại.
                    </li>
                    <li>
                      Không cho trẻ ăn các thức ăn nhiều dầu mỡ, đồ ăn chiên
                      xào, thay vào đó có thể hấp, luộc, cho trẻ ăn ít gia vị và
                      những loại đồ ăn vừa chứa chất béo vừa nhiều đường như
                      bánh ngọt, bánh nướng, khoai tây chiên, xúc xích, kem . Có
                      thể cho trẻ ăn nhiều rau xanh, các loại trái cây .
                    </li>
                    <li>
                      Tập cho trẻ ăn thịt cá nạc, ăn cá nhiều hơn thịt, chú ý
                      các thực phẩm giàu Canxi, Sắt, Kẽm (sữa, trứng, thịt, cá,
                      tôm, cua,…).
                    </li>
                    <li>
                      Không cho trẻ ăn quá no, không cho ăn quà vặt, không bỏ
                      bữa.
                    </li>
                    <li>
                      Đảm bảo cho trẻ ngủ đủ giấc, ngủ sớm trước 9 giờ tối.
                    </li>
                    <li>
                      Thường xuyên cho trẻ vận động, chạy nhảy ngoài trời, chơi
                      ném bóng, đá bóng, đạp xe đạp 3 bánh…
                    </li>
                    <li>
                      Hạn chế bồng bế. Hạn chế cho trẻ ngồi lâu một chỗ, xem ti
                      vi, ngồi trước màn hình vi tính, điện thoại (không quá 2
                      giờ/ngày).
                    </li>
                    <li>
                      Tái khám, theo dõi cân nặng và chiều cao của trẻ hàng
                      tháng để điều chỉnh chế độ ăn và vận động phù hợp.
                    </li>
                  </ul>
                  <p style="font-style: italic">
                    (Sử dụng thêm CalciO – D3 K2 (MK7) để hỗ trợ con trong việc
                    dung nạp, hấp thu calci từ máu vào xương trong giai đoạn
                    vàng để phát triển chiều cao).
                  </p>
                </div>
                <div class="kdf-sp" style="position: relative">
                  <div class="kdf-sp-img1" style="width: 50%; display: block">
                    <img class="" src="@/assets/img/3lo.png" />
                  </div>
                  <div class="button-kqf" style="">
                    <img
                      class="logo-heading-avaluate"
                      src="@/assets/img/logo-heading-2.png"
                      style="display: block"
                    />
                    <a class="btn-form-eva" href="/" id="btn-form-eva-s"
                      >Tìm hiểu về Obaby</a
                    >
                    <br />
                    <a class="btn-form-eva" @click="isShowBuyModal = true"
                      >Mua ngay</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </section>
    <registerObaby v-if="isShowModal" />
    <feedbackcheck v-if="isShowModal" />
  </div>
</template>

<script>
import buyModal from "@/view/Dialog/buy-modal.vue";
import registerObaby from "@/view/Home/components/register-obaby.vue";
import feedbackcheck from "@/view/Physical/components/feedback-bmi.vue";
export default {
  components: {
    registerObaby,
    buyModal,
    feedbackcheck,
  },
  data() {
    return {
      isShowModal: false,
      bmiChildren: {
        NameChidren: "",
        AgeChidren: "",
        SexChidren: "",
        Weight: null,
        Height: null,
      },
      statusPhysical: {
        physicalCondition: "",
        Malnutrition: "Bé bị suy dinh dưỡng",
        Underweight: "Bé hơi thiếu cân",
        Normal: "Bé đang phát triển trong giới hạn bình thường",
        Overweight: "Bé hơi thừa cân",
        Fat: "Bé bị béo phì",
      },
      isShowEvaluate: false,
      kq11: false,
      kq12: false,
      kq13: false,
      kq14: false,
      kq15: false,
      kq21: false,
      kq22: false,
      kq23: false,
      kq24: false,
      kq25: false,
      kq31: false,
      kq32: false,
      kq33: false,
      kq34: false,
      kq35: false,
      kq43: false,
      kq44: false,
      kq45: false,
      kq53: false,
      kq54: false,
      kq55: false,
      resultBmi: 0,
      weightNumber: 0,
      heightNumber: 0,
      isShowBuyModal: false,
    };
  },
  methods: {
    checkBmiIndex() {
      this.resultBmi =
        this.bmiChildren.Weight /
        ((this.bmiChildren.Height / 100) * (this.bmiChildren.Height / 100));
      this.resultBmi = this.resultBmi.toFixed(2);
      if (this.bmiChildren.AgeChidren == "0-5 tháng") {
        if (this.resultBmi <= 16) {
          this.kq11 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi > 16 && this.resultBmi < 21) {
          this.kq21 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21) {
          this.kq31 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "6-11 tháng") {
        if (this.resultBmi <= 16) {
          this.kq12 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi > 16 && this.resultBmi < 21) {
          this.kq22 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21) {
          this.kq32 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "12-17 tháng") {
        if (this.resultBmi <= 16) {
          this.kq13 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 16 && this.resultBmi < 21) {
          this.kq23 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21) {
          this.kq33 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "18-23 tháng") {
        if (this.resultBmi <= 16) {
          this.kq13 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 16 && this.resultBmi < 21) {
          this.kq23 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21) {
          this.kq33 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "2") {
        if (this.resultBmi < 14) {
          this.kq13 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16) {
          this.kq23 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16 && this.resultBmi < 18) {
          this.kq33 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 18 && this.resultBmi < 20) {
          this.kq43 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi >= 20) {
          this.kq53 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "3") {
        if (this.resultBmi < 14) {
          this.kq13 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16) {
          this.kq23 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16 && this.resultBmi < 18) {
          this.kq33 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 18 && this.resultBmi < 20) {
          this.kq43 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi >= 20) {
          this.kq53 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "4") {
        if (this.resultBmi < 14) {
          this.kq14 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16) {
          this.kq24 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16 && this.resultBmi < 17.5) {
          this.kq34 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 17.5 && this.resultBmi < 18.5) {
          this.kq44 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi >= 18.5) {
          this.kq54 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "5") {
        if (this.resultBmi < 14) {
          this.kq14 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 15.5) {
          this.kq24 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 15.5 && this.resultBmi < 17) {
          this.kq34 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 17 && this.resultBmi < 18.5) {
          this.kq44 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi >= 18.5) {
          this.kq54 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "6") {
        if (this.resultBmi < 13) {
          this.kq14 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 13 && this.resultBmi < 15) {
          this.kq24 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 15 && this.resultBmi < 17) {
          this.kq34 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 17 && this.resultBmi < 19) {
          this.kq44 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi >= 19) {
          this.kq54 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "7") {
        if (this.resultBmi < 13) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 13 && this.resultBmi < 15.5) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 15.5 && this.resultBmi < 17.5) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 17.5 && this.resultBmi <= 19) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 19) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "8") {
        if (this.resultBmi < 14) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16 && this.resultBmi < 19) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 17.5 && this.resultBmi <= 21) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 21) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "9") {
        if (this.resultBmi < 14) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16 && this.resultBmi < 18.5) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 18.5 && this.resultBmi <= 21) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 21) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "10") {
        if (this.resultBmi < 14) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 14 && this.resultBmi < 16.5) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16.5 && this.resultBmi < 19) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 19 && this.resultBmi <= 23) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 21) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "11") {
        if (this.resultBmi < 15) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 15 && this.resultBmi < 16) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16.5 && this.resultBmi < 20) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 20 && this.resultBmi <= 23) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 21) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "12") {
        if (this.resultBmi < 15) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 15 && this.resultBmi < 17.5) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 16.5 && this.resultBmi < 21) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21 && this.resultBmi <= 24) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 24) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else if (this.bmiChildren.AgeChidren == "Trên 12") {
        if (this.resultBmi < 15) {
          this.kq15 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Malnutrition;
        } else if (this.resultBmi >= 15 && this.resultBmi < 17) {
          this.kq25 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Underweight;
        } else if (this.resultBmi >= 17 && this.resultBmi < 21) {
          this.kq35 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Normal;
        } else if (this.resultBmi >= 21 && this.resultBmi <= 24) {
          this.kq45 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition =
            this.statusPhysical.Overweight;
        } else if (this.resultBmi > 24) {
          this.kq55 = true;
          this.isShowEvaluate = true;
           this.isShowModal = true;
          this.statusPhysical.physicalCondition = this.statusPhysical.Fat;
        }
      } else {
      }
     

    },
  },
};
</script>

<style lang="css" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bmiform-section {
  background: #031a45;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 200px;
}

.container-check {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row-check {
  margin-right: -15px;
  margin-left: -15px;
}
.bmiform-section .bmif-lead {
  font-family: Soup;
  color: white;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 30px;
}
.under-leading-white-check {
  margin-bottom: 70px;

  position: relative;
  overflow: hidden;
}
.center-check {
  text-align: center;
}
.under-leading-white-check img {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: text-top;
}

.bmiform-section .bmif-single {
  position: relative;
  margin-bottom: 30px;
}
.bmiform-section .bmif-single label {
  font-family: "Quicksand SemiBold";
  color: #fff;
  font-size: 20px;
  margin-bottom: 15px;
  position: absolute;
  left: 0;
}
label {
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
}
.bmi-up {
  width: 1170px;
  position: relative;
}
#top-item-check1 {
  display: inline-block;
  width: 555px;
}
#top-item-check2 {
  display: inline-block;
  width: 555px;
  margin-left: 30px;
}
#name-check,
#weight,
#height {
  margin-top: 45px;
}
.bmif-single select,
input {
  font-family: "Quicksand Regular" !important;
  font-size: 17px !important;
  padding: 10px !important;
  float: left !important;
  outline: 0 !important;
  width: 100% !important;
  border: none !important;
  border-radius: 0 !important;
  height: 41px;
  margin-top: 45px;
}
#top-item-check3,
#top-item-check5,
#top-item-check4 {
  display: inline-block;
  width: 360px;
  height: 43px;
}
#top-item-check3 select {
  margin-top: 45px;
}
.bmif-single:nth-child(4) {
  margin-left: 30px;
  margin-right: 30px;
}
.bmif-button {
  display: inline-block;
  text-align: center;
  width: 97%;
}
.bmiform-section .bmif-button input {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 25px !important;
  cursor: pointer;
  height: 72px;
  padding: 20px 10px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #cd2653;
  transition: opacity 0.15s linear;
  font-weight: 600;
  letter-spacing: 0.0333em;
  line-height: 1.25;
  margin: 0;
  text-align: center;
  opacity: 1;
  color: #fff;
  margin-top: 80px;
}
.bmiq-evaluate {
  background: #031a45;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 200px;
}
.container-eveluate {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row-evaluate {
  margin-right: -15px;
  margin-left: -15px;
}
.kq-evaluate {
  background: white;
  border-radius: 40px;
  padding: 40px;
}
.bmiq-evaluate .kq-evaluate .kq-lead-evaluate {
  font-family: Soup;
  color: #02959c;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
}
.under-leading-white-evaluate {
  margin-bottom: 50px;
}
.obb-icon-evaluate {
  display: flex;
  justify-content: center;
}
.obb-icon {
  display: flex;
  justify-content: center;
}
.under-leading-white-evaluate img {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: text-top;
}
.line-wrap {
  margin-top: 15px;
  width: 60px;
  height: 4px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
.line-wrap-evaluate {
  margin-top: 15px;
  width: 60px;
  height: 4px;
  background-color: #02959c;
  margin-left: 20px;
  margin-right: 20px;
}
.bmiq-evaluate .kq-evaluate .blue-wrap-evaluate {
  float: left;
  width: 80%;
  margin: 0 10%;
  background: #02959c;
  padding: 40px 50px 20px;
  margin-bottom: 40px;
  border-radius: 50px;
}
.bmiq-evaluate .kq-evaluate .blue-wrap-evaluate .kqf-title-evaluate,
.bmiq-evaluate .kq-evaluate .blue-wrap-evaluate .kqf-kq {
  padding: 0;
  font-family: "Quicksand Bold";
  color: white;
  font-size: 20px;
  display: inline-block;
  text-align: start;
}
.kqf-lead {
  font-family: Soup;
  color: #02959c;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
}
.bmiq-evaluate .kq-evaluate .single-kq p,
li {
  font-family: "Quicksand Regular";
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
  left: 0;
  text-align: start;
}

li {
  line-height: 1.5;
  margin: 5px 0 0 20px;
  padding: 0;
}

.single-kq {
  font-family: "Quicksand Regular";
  color: black;
  font-size: 17px;
  font-weight: 600;
}
.kdf-sp {
  padding-top: 50px;
}
.logo-heading-avaluate {
  max-width: 300px;
  margin: 0 auto 20px;
}
.button-kqf {
  width: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.btn-form-eva {
  font-family: "Quicksand Bold";
  padding: 18px 50px;
  background: #02959c;
  color: white;
  border-radius: 30px;
  margin-bottom: 30px;
  float: left;
  width: 515px;
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 15px;
  text-decoration: none;
}
.form-section-register {
  padding-top: 100px;
  padding-bottom: 40px;
}
.container-register-check {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 1170px;
}
.row-register-check {
  margin-right: -15px;
  margin-left: -15px;
}
.form-registerp-check {
  display: inline-block;
  width: 50%;
}
.form-wrap-register {
  width: 100%;
}
.element-register-check {
  padding-right: 0;
  width: 262px;
  display: inline-block;
  margin-bottom: -12px;
}
.child-register {
  position: relative;
}

.child-register input {
  font-family: "Quicksand Regular" !important;
  font-size: 17px !important;
  padding: 10px !important;
  float: left !important;
  outline: 0 !important;
  width: 100% !important;
  border: none !important;
  border-radius: 0 !important;
}
.form-register-btn {
  padding: 10px;
  font-family: "Quicksand Bold";
  font-size: 17px;
  background: #02959c;
  color: white;
  display: block;
  text-align: center;
  text-transform: uppercase;
}
.form-register-infor {
  width: 585px;
  display: inline-block;
}
.form-register-infor-lead {
  font-family: Soup;
  font-size: 33px;
  text-align: left;
  margin-bottom: 10px;
  color: white;
}
.form-register-infor-sub {
  font-size: 18px;
  line-height: 1.2;
  color: white;
  text-align: start;
}
@media (min-width: 1200px) {
  .container-register-check {
    width: 1170px;
  }
  .container-check {
    width: 1170px;
  }
  .container-eveluate {
    width: 1170px;
  }
  .kdf-sp .kdf-sp-img1 img {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1170px) {
  #top-item-check2 {
    width: 445px !important;
  }
  #top-item-check1 {
    width: 445px !important;
  }
  #top-item-check3,
  #top-item-check4,
  #top-item-check5 {
    width: 290px !important;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 900px) {
  #top-item-check2 {
    width: 426px;
  }
  #top-item-check1 {
    width: 426px;
  }
  #top-item-check3,
  #top-item-check4,
  #top-item-check5 {
    width: 275px;
  }
  .bmif-button {
    width: 890px;
  }

  .kq-evaluate {
    display: inline-block;
    width: 90%;
  }
  .kdf-sp .kdf-sp-img1 img {
    width: 100% !important;
  }
  .btn-form-eva {
    width: 100%;
    height: 63px;
  }
  #btn-form-eva-s {
    margin-bottom: 17px;
  }
}
@media only screen and (max-width: 900px) {
  .bmiform {
    display: block;
    text-align: center;
  }
  #top-item-check2 {
    margin-left: 0;
    width: 90%;
  }
  #top-item-check1 {
    margin-left: 0;
    width: 90%;
  }
  #top-item-check3 {
    margin-left: 0;
    width: 90%;
    height: 80px;
  }
  #top-item-check4 {
    margin-right: 0;
    margin-left: 0;
    width: 90%;
    height: 80px;
  }
  #top-item-check5 {
    margin-right: 0;
    margin-left: 0;
    width: 90%;
  }
  .bmif-button {
    width: 90%;
  }
  .button-kqf {
    position: relative !important;
    display: contents;
  }
  .kq-evaluate {
    display: inline-block !important;
    width: 90%;
  }
  .kdf-sp .kdf-sp-img1 {
    display: inline-block;
    width: 100% !important;
  }
  .kdf-sp .kdf-sp-img1 img {
    width: 100% !important;
  }
  .logo-heading-avaluate {
    width: 100% !important;
  }
  .btn-form-eva {
    width: 100%;
    height: 55px;
    padding: 12px 35px;
  }
  #btn-form-eva-s {
    margin-bottom: 17px;
    height: 55px;
  }
}
@media (max-width: 600px) {
  .bmiform-section .bmif-lead {
    font-size: 35px;
  }
}
@media (min-width: 714px) {
  .kqf-title-evaluate,
  .kqf-kq {
    width: 33.3%;
  }
}
</style>
