import { render, staticRenderFns } from "./feedback-obaby.vue?vue&type=template&id=d8588332&scoped=true&"
import script from "./feedback-obaby.vue?vue&type=script&lang=js&"
export * from "./feedback-obaby.vue?vue&type=script&lang=js&"
import style0 from "./feedback-obaby.vue?vue&type=style&index=0&id=d8588332&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8588332",
  null
  
)

export default component.exports