<template>
  <div>
    <div class="content-container bg-image">
      <div class="content-info">
        <p class="content-title">Thế nào là biếng ăn hấp thu kém</p>
        <div class="frame-content">
          <div class="line-content"></div>
          <img src="@/assets/img/obb-icon.png" alt="" />
          <div class="line-content"></div>
        </div>
        <div class="description">
          <div class="des-img">
            <img src="@/assets/img/intro-1.png" alt="" />
          </div>

          <div class="des-content" style="width: 650px">
            <div class="des1">
              <img
                src="@/assets/img/bg-intro.png"
                alt=""
                width="100%"
                class="img-des"
              />
              <p>
                Bé ăn được nhưng không lớn
                <br />trong thời gian dài (trên 3 tháng)
              </p>
            </div>
            <div class="des1">
              <img
                src="@/assets/img/bg-intro.png"
                alt=""
                width="100%"
                class="img-des"
              />
              <p>
                Bé ăn ít
                <br />
                không muốn ăn, ăn hay ngậm
              </p>
            </div>
            <div class="des1">
              <img
                src="../../../assets/img/bg-intro.png"
                alt=""
                width="100%"
                class="img-des"
              />

              <p>
                Bé không lên kí, nhẹ cân so với chuẩn nhưng đưa đi khám thi
                không ra bệnh gì
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-star">
      <img src="@/assets/img/rocket.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content-container {
  background-color: #f5f5f5;
  background-image: url("@/assets/img/bg-star.png");
  background-position: center;
  background-size: cover;
  // height: 600px;
  .content-title {
    line-height: 1.5;
    margin: 0 0 1em 0;
    padding: 70px 0 0px;
    margin-bottom: 10px;
    font-family: Soup;
    font-size: 40px;
    text-align: center;
    color: #02959c;
  }

  .frame-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    img {
      height: 35px;
      width: 35px;
      display: inline-block;
      vertical-align: text-top;
      margin: auto 16px;
    }
    .line-content {
      //   right: 52%;
      margin-top: 15px;
      width: 60px;
      height: 4px;
      background-color: #02959c;
    }
  }
  .description {
    margin-top: 55px;
    display: flex;
    justify-content: center;
    .des-img {
      margin-right: 100px;
      margin-left: 50px;
    }

    img {
      height: auto;
    }
    .des1 {
      position: relative;
      img {
        z-index: -1;
        width: 95%;
        margin: 16px 0px;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        font-family: "Quicksand Bold";
        font-size: 21px;
        text-align: center;
        width: 403px;
        top: 70px;
        color: #fff;
      }
    }
  }
}
.content-star {
  background-color: #f5f5f5;
  background-image: url("@/assets/img/bg-star.png");
  background-position: center;
  background-size: cover;
  padding-top: 60px;
  img {
    width: 100%;
    max-width: 600px;
  }
}
@media only screen and (min-width: 995px) {
  .des-img img {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 995px) {
  .content-container .description .des1 p {
    top: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .content-container {
    .content-info {
      p {
        padding-top: 45px;
        font-size: 33px;
      }
      .description {
        display: block;
        .des-img {
          margin: 40px 0;
        }
        .des-content {
          width: 60% !important;
          display: inline-block;
          .des1 {
            margin-top: 30px;

            img {
              // padding: 0;
              margin: 0px;
            }
            p {
              top: 18px;
              font-size: 19px;
              width: 370px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .content-container {
    .content-info {
      p {
        padding-top: 45px;
        font-size: 33px;
      }
      .description {
        display: block;
        .des-img {
          margin: 40px 0;
        }
        .des-content {
          width: 100%;

          .des1 {
            margin-top: 0;
            img {
              // padding: 0;
              margin: 10px 0;
              width: 90%;
            }
            p {
              width: 300px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .content-container .content-info .description .des-img img{
    width: 100%;
  }
  .content-container {
    .content-info {
      p {
        padding-top: 45px;
        font-size: 33px;
      }
      .description {
        display: block;
        .des-img {
          margin: 40px 0;
          
        }
        .des-content {
          width: 90% !important;
          .des1 {
            img {
              // padding: 0;
              margin: 10px 0;
              width: 100%;
            }
            p {
              top: 15px;
              width: 82%;
              font-size: 19px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .content-container .description img {
    width: 90%;
    display: inline-block;
  }
  .content-container .content-info .description .des-content .des1 img {
    height: 102px;
  }
  .content-container .content-info .description .des-content .des1 p {
    margin-top: 21px !important;
  }
 .sub {
    width: 90%  !important;
  }
  .news-container .col .col-item .row img {
    width: 220px !important;
  }
  .news-container .title {
    font-size: 42px !important;
  }
  .content-container .description img{
    width: 100%;
  }
  .bottom .container{
    width: 360px;
  }
  .news-container .col .col-item .row img{
    padding: 0;
    object-fit: cover;
  }
  .bottom .container .title-register2 .sub{
    width: 97%;
  }
}

</style>
