<template>
  <div class="bottom">
    <div class="container">
      <div class="title-register2">
        <p class="title">Đăng ký Chuyên gia tư vấn miễn phí</p>
        <p class="sub">
          Các mẹ vui lòng để lại thông tin Họ tên, Số điện thoại và tuổi của bé.
          Chuyên gia dinh dưỡng sẽ liên hệ để giải đáp các thắc mắc cũng như
          chứng biến ăn ở trẻ nhỏ.
        </p>
      </div>
      <div style="container-input" class="input-form-box">
        <p
          v-if="isShowWarning"
          class="mt-2"
          style="color: #fff; margin-bottom: -10px"
        >
          {{ contentWarning }}
        </p>
        <div class="input-value">
          <div class="input-name-age" style="margin-right: -24px">
            <div class="container-input">
              <input
                class="name"
                type="text"
                placeholder="Họ và tên mẹ"
                v-model="formRegister.FullName"
              />
            </div>
            <div class="container-input">
              <input
                class="age"
                type="text"
                placeholder="Tuổi con"
                v-model="formRegister.Desctiption"
              />
            </div>
          </div>
          <div class="input-phone-res">
            <div class="container-input">
              <input
                class="phone"
                type="text"
                placeholder="Số điện thoại"
                v-model="formRegister.PhoneNumber"
              />
            </div>
            <button class="bt-register" @click="createWebRegister">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-if="loadding"
              ></span>
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>

      <div class="title-register">
        <p class="title">Đăng ký Chuyên gia tư vấn miễn phí</p>
        <p class="sub">
          Các mẹ vui lòng để lại thông tin Họ tên, Số điện thoại và tuổi của bé.
          Chuyên gia dinh dưỡng sẽ liên hệ để giải đáp các thắc mắc cũng như
          chứng biến ăn ở trẻ nhỏ.
        </p>
      </div>
    </div>

    <div
      style="
        position: fixed;
        z-index: 100;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
      "
      v-if="isShowSuccess"
      @click="isShowSuccess = false"
    >
      <div class="buy-success">
        <i class="fa-solid fa-circle-check"></i>
        <p class="title">Thành công</p>
        <p class="sub">
          Cám ơn mẹ! Nhân viên CSKH sẽ sớm liên lạc lại với mẹ bé trong thời
          gian sớm nhất!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
      },
      loadding: false,
      isShowWarning: false,
      isShowSuccess: false,
      contentWarning: "Các mẹ vui lòng điền đủ thông tin!",
    };
  },
  methods: {
    btClose() {
      this.$emit("btCloseContent", false);
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.formRegister.Desctiption.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Các mẹ vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }

      this.loadding = true;
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "Obaby",
          TypeDoc: "TV",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.formRegister.Desctiption,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-success {
  z-index: 1 !important;
  border-radius: 8px;
  margin: auto;
  overflow: hidden;
  background-color: #fff !important;
  padding: 30px;
  max-width: 400px !important;
  i {
    color: rgb(37, 221, 46);
    font-size: 70px;
  }
  .title {
    font-family: "Quicksand SemiBold";
    font-size: 24px;
    margin-top: 10px;
    color: #02959c;
  }
  .sub {
    font-family: "Quicksand Medium";
    font-size: 18px;
    color: #02959c;
  }
}

.bottom {
  z-index: 0 !important;
  background: #e05d4b;
  .container {
    display: flex;
    justify-content: center;
    padding: 50px 0;
    padding-top: 100px;
    .container-input {
      width: 100%;
    }
    .input-value {
      width: 100%;
      display: flex;
      margin-top: -28px;
      .input-name-age {
        padding: 20px;
      }
      .input-phone-res {
        padding: 20px;
      }
      .container-input {
        background: #fff;
        padding-left: 8px;
        // padding: 20px;
        margin: 20px auto;
        .name {
          width: calc(100% - 16px);
          border: none;
          font-size: 17px;
          height: 41px;
        }
        .age {
          width: calc(100% - 16px);
          border: none;
          font-size: 17px;
          height: 41px;
        }
        .phone {
          width: calc(100% - 16px);
          border: none;
          font-size: 17px;
          height: 41px;
          //   margin-bottom: 40px;
        }
      }

      .bt-register {
        width: calc(100%);
        border: none;
        font-size: 17px;
        height: 41px;
        background: #02959c;
        color: #fff;
        font-family: "Quicksand Semibold" !important;
        text-transform: uppercase;
      }
      .bt-register:hover {
        background-color: #e35b89;
      }
    }
    .title-register2 {
      display: none;
    }
    .title-register {
      width: 50%;
      padding-left: 10px;
      .title {
        font-family: Soup;
        font-size: 33px;
        text-align: left;
        margin-bottom: 10px;
       
        color: white;
      }
      .sub {
        font-family: "Quicksand Regular";
        text-align: left;
        font-size: 18px !important;
        margin-top: -7px;
        line-height: 1.2;
        color: #fff;
      }
    }
  }
  input:focus-visible {
    outline: none;
  }
}
@media only screen and (max-width: 1200px) {
  .bottom .container .title-register .sub {
    font-family: "Quicksand Regular";
    text-align: left;
    font-size: 18px;
    width: 87%;
    margin-top: -7px;
    line-height: 1.2;
    color: #fff;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px)
{
  .title-register2{
    display: none;
  }
  .bottom .container .title-register{
    width: 460px;

  }
  .bottom .container .title-register.title{
    font-size: 26px;

  }
  .bottom .container .title-register .sub{
    width: 91%;
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 800px){
  .bottom {
    margin-top: 50px;
    .container {
      .input-form-box{
        width: 40%;
        display: inline-block;
      }
    
      padding-top: 80px;
      .title-register2 {
        display: none;
      }
      .title-register {
        display: inline-block !important;
        
        
        .title {
          font-family: Soup;
          font-size: 24px;
          text-align: left;
          margin-bottom: 10px;
          color: white;
        }
        .sub {
          font-family: "Quicksand Regular";
          text-align: left;
          font-size: 16px !important;
          width: 80%;
          margin-top: -7px;
          line-height: 1.2;
          color: #fff;
        }
      }
      .container-input {
        width: 100% !important;
      }
      // .input-value {
      //   width: 100% !important;
      // }
    }
  }
  .bottom .container .input-value .container-input .name{
    font-size: 16px;
  }
  .bottom .container .input-value .container-input .phone{
   font-size: 16px;
  }
  .bottom .container .input-value .container-input .age{
   font-size: 16px;
  }
  .bottom .container .input-value .bt-register{
    font-size: 15px;
  }
}
@media only screen and (max-width: 800px) {
  .bottom .container{
    display: block;

  }
  .title-register{
    display: none;
  }
  .title-register2{
    display: inline-block !important;
  }
  .bottom{
    margin-top: 0;
  }
  .input-value {
    display: block !important;
    .input-name-age {
      padding-bottom: 0 !important;
    }
    .input-phone-res {
      padding-top: 0 !important;
    }
  }
  .bottom .container{
    padding-top: 80px;
  }
  .bottom .container .title-register2{
    width: 96%;
    margin: 0 10px;
    padding: 0 20px;
  }
  .bottom .container .title-register2 .title {
    color: #fff;
    font-family: "Soup";
    font-size: 22px;
    line-height: 33px;
    margin: 0 0 10px;
    text-align: left;
  }
  .bottom .container .title-register2 .sub {
    color: #fff;
    font-family: "Quicksand Regular";
    font-size: 18px;
    line-height: 21.6px;
    text-align: justify;
    
  }
  .input-form-box {
    margin: 0 10px;
  }
  .input-name-age {
    margin-right: 0 !important;
  }
  
}
@media only screen and (max-width: 500px){
  .title-register2{
  display: block !important;
  }
}

</style>
