<template>
  <div>
    <buyModal v-if="isShowBuyModal" @btClose="isShowBuyModal = false" />
    <div
      class="dialog-ads"
      style="display: block"
     
    >
      <div @click="closeAdsDialog">
        <img
          src="@/assets/img/x.png"
          alt="Close"
          class="close-btn-ads"
          style="
            z-index: 220;
            position: absolute;
            float: left;
            top: 0px;
            right: 0px;
            width: 20px;
            cursor: pointer;
            height: 20px;
            background-repeat: no-repeat;
            background-size: cover;
            
          "
        />
      </div>
      <div>
        <img src="@/assets/img/pop-up.png" alt="" class="dialog-img-ads"  @click="isShowBuyModal = true"/>
      </div>
    </div>
  </div>
</template>

<script>
import buyModal from "@/view/Dialog/buy-modal.vue";
export default {
   data() {
    return {
      isShowBuyModal: false,
     
    };
   },
  components: {
    buyModal,
  },
  methods: {
    closeAdsDialog() {
      var x = document.getElementsByClassName("dialog-ads");
     
      if (x[0].style.display === "block") {
        x[0].style.display = "none";
      } else {
        x[0].style.display = "block";
      }
    },
  },
};
</script>

<style>
a.to-the-top {
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 10000;
  background: #02959c;
  padding: 10px 17px;
  border-radius: 53%;
  color: white;
  font-weight: 800;
  display: none;
  font-size: 25px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
}
a.to-the-top {
  color: white !important;
}
.dialog-ads {
  z-index: 210;
  position: fixed;
  left: 20px;
  bottom: 2px;
  animation-timing-function: linear;
  animation-duration: 500ms;
  cursor: pointer;
}
.dialog-img-ads {
  width: 120px;
  margin-bottom: 50px;
}
</style>