<template>
  <div>
    <div class="news-container container">
      <p class="title">Mẹ có biết?</p>
      <p class="sub">
        Giai đoạn vàng cho việc phát triển thể chất, trí tuệ của con là từ 2-6
        tuổi, trong giai đoạn này, biếng ăn có nhiều nguyên nhân, cả chủ quan và
        khách quan
      </p>
      <div class="col">
        <div class="col-item">
          <div class="row" style="margin-right: 3px;">
            <img src="@/assets/img/kno-1.png" alt="" />
            <p class="title-row">BIẾNG ĂN SINH LÝ</p>
            <p class = "infor-new-obaby">
              Do những thay đổi sinh lý của trẻ giữa các giai đoạn biết lật,
              biết ngồi, biết bò, biết đi, mọc răng,... đều là nguyên nhân khiến
              trẻ biếng ăn.
              <b
                >Biếng ăn sinh lý thương theo đợt khoảng 3-6 tháng/lần với trẻ
                nhỏ</b
              >
            </p>
          </div>
          <div class="row" style="margin-left: 3px;">
            <img src="@/assets/img/kno-2.png" alt="" />
            <p class="title-row">BIẾNG ĂN TÂM LÝ</p>
            <p class = "infor-new-obaby">
              Biếng ăn tâm lý thường xảy ra khi trẻ có cảm giác
              <b>BỊ ÉP BUỘC</b> như: phải ngồi một chỗ từ đầu đến cuối bữa ăn,
              bị quy định phải ăn hết phần ăn của mình trong một thời gian cố
              định, không khí bữa ăn căng thẳng.
              <b
                >Biếng ăn tâm lý lâu dần có thể chuyển đổi thành biếng ăn bệnh
                lý</b
              >
            </p>
          </div>
        </div>
        <div class="col-item">
          <div class="row" style="margin-right: 3px;">
            <img src="@/assets/img/kno-3.png" alt="" />
            <p class="title-row">BIẾNG ĂN BỆNH LÝ</p>
            <p class = "infor-new-obaby">
              Các bệnh lý về răng miệng, đường tiêu hóa, ký sinh trùng đặc biệt
              là đường hô hấp cũng làm giảm thể trạng của trẻ, gây ra biếng ăn
              trong suốt thời gian bệnh lý. Việc hỗ trợ sau bệnh lý rất quan
              trọng để
              <b>phục hồi sức khỏe của trẻ sau bệnh lý</b>
            </p>
          </div>
          <div class="row" style="margin-left: 3px;">
            <img src="@/assets/img/kno-4.png" alt="" />
            <p class="title-row" id = "last-title-row">VÒNG TRÒN LUẨN QUẨN</p>
            <p class = "infor-new-obaby">
              Khi bé biếng ăn, hấp thu kém, bố mẹ cần hiểu đúng để hỗ trợ đúng
              tránh việc để con rơi vào vòng tròn
              <b>Biếng ăn -> Đề kháng kém -> Hay ốm vặt -> Biếng ăn…</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>

.news-container {
  padding-top: 50px;
  .title {
    font-family: Soup;
    color: #02959c;
    font-size: 50px;
    margin-bottom: 10px;
  }
  .sub {
    font-family: "Quicksand Bold";
    font-size: 23px;
    text-align: justify;
    text-align-last: center;
    margin: 0 auto;
    max-width: 600px;
  }
  .col {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    .col-item {
      margin-right: 28px;
      display: flex;
      justify-content: space-evenly;
      .row {
        width: 50%;
        height: 600px;
        width: 263px;
      
        img {
        
          margin: 0 auto;
          
          width: auto;
          margin-bottom: 10px;
        }
        p {
          margin-top: 0;
          font-family: "Quicksand Regular";
          font-size: 17px;
          text-align: justify;
          text-align-last: center;
          
          b {
            color: #000;
          }
          margin-bottom: 160px;
        }
        .title-row {
          color: #000;
          font-size: 20px;
          margin-top: 20px;
          font-weight: 25px;
          margin-bottom: 10px;
          font-family: "Quicksand Bold";
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (max-width: 1920px){
    .news-container .col .col-item .row img{
      height: 220px;
    }
}
@media only screen and (max-width: 991px) {
  .news-container {
    .title {
      font-size: 40px !important;
    }
    .sub {
      font-size: 20px !important;
      text-align-last: center;
    }
    .col {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .col-item {
        display: block;
        .row {
          width: 100%;
          height: 500px;
          margin-top: 40px;
          margin-left: 0;
          img {
            max-width: 220px;
            margin: 0 auto;
            height: 220px;
            width: auto;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .news-container .col .col-item {
    margin-right: 0px;

  }
  .news-container {
    .title {
      font-size: 36px !important;
    }
    .sub {
      font-size: 18px !important;
      width: 90%;
    }
    .col {
      margin-top: 30px;
      display: block;
      width: 100%;
      .col-item {
        display: block;
        .row {
          margin-left: 0 !important;
          width: 100%;
          height: 500px;
          img {
            max-width: 220px;

            margin: 0 auto;
            height: 220px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .news-container .title{
    font-size: 42px !important;
  }
  .news-container .sub{
    width: 84%;
  }
  .news-container .col .col-item .row p{
    width: 88%;
    margin: 0 auto;
  }
  .news-container .col .col-item .row{
    margin-bottom: 50px;
  }
}
</style>
