<template>
  <div class="container-feedback">
    
    <p class="title-feedback">Phản hồi từ các mẹ thông thái</p>
    <div class="frame-content">
      <div class="line-content"></div>
      <img src="@/assets/img/obb-icon.png" alt="" />
      <div class="line-content"></div>
    </div>
    <div class="container" style="margin-bottom: 140px">
      <div
        class="single-fb"
        v-for="(item, index) in dataFacebookLst"
        :key="index"
      >
        <div class="single-cmt-fb">
          <div class="ava-fb">
            <img :src="item.img" alt="" />

          </div>
          <div class="cmt-fb">
            <img src="@/assets/img/cmt-fb-1.jpg" alt="" class="img-min"/>
             <img src="@/assets/img/cmt-fb-2.jpg" alt="" class="img-large"/>
            <div class="fb-section">
              <p class="fb-name">{{ item.name }}</p>
              <p class="fb-txt">
                {{ item.cmt }}
              </p>
            </div>
            <div class="fb-date">
              <p>
                <span>Thích</span><span>Trả lời</span
                ><span class="fb-time">{{ item.time }} giờ trước</span>
              </p>
            </div>
            <div class="fb-like">
              <p>{{ item.like }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import url1 from "@/assets/img/ava-fb-1.png";

import url2 from "@/assets/img/ava-fb-2.png";
import url3 from "@/assets/img/ava-fb-3.png";
import url4 from "@/assets/img/ava-fb-4.png";
import url5 from "@/assets/img/ava-fb-5.png";
import url6 from "@/assets/img/ava-fb-6.png";
import buyModal from "@/view/Dialog/buy-modal.vue";
export default {
  data() {
    return {
      dataFacebookLst: [
        {
          name: "Nguyễn Hồng Hạnh",
          cmt: "Bé nhà mình sử dụng Obaby 123 trộm vía đã ăn ngon hơn nhiều. Ship thêm cho mình 2 lọ về địa chỉ cũ nhé!",
          time: "2",
          like: "24",
          img: url1,
        },
        {
          name: "Nguyễn Thị Yến",
          cmt: "Vị rất ngon, 2 đứa nhà mình rất thích uống. Xi lanh phân liều rất dễ sử dụng và vệ sinh nhé các mom. Giá rẻ nữa",
          time: "3",
          like: "16",
          img: url2,
        },
        {
          name: "Lê Hoa",
          cmt: "Obaby giúp bé nhà mình ăn ngon miệng, tự giác ăn uống mà không phải bắt ép hay bế dong như trước nữa",
          time: "5",
          like: "29",
          img: url3,
        },
        {
          name: "Phương Linh",
          cmt: "Gửi 3 hộp theo địa chỉ cũ nhé bạn. Thấy bé ăn nhiều hơn trước với ngủ cũng ngon giấc hơn nên mình mới đặt thêm",
          time: "7",
          like: "15",
          img: url4,
        },
        {
          name: "Linh Phạm",
          cmt: "Con em dùng thấy hiệu quả, bé thèm ăn hơn thường. Còn 2 hộp nữa là đủ liệu trình, hi vọng bé tăng cân tốt hơn.",
          time: "12",
          like: "32",
          img: url5,
        },
        {
          name: "Vicky Lê",
          cmt: "Bé nhà mình dùng được thời gian ngắn thì tăng được 1.5 kg. Không biết tiếp tục uống hay ngừng vậy ad ơi?",
          time: "14",
          like: "22",
          img: url6,
        },
      ],
      isShowBuyModal: false,
    };
  },
};
</script>

<style lang="scss" scope>
.img-large{
  display: none;
}
.container-feedback {
  .title-feedback {
    font-family: Soup;
    font-size: 40px;
    text-align: center;
    padding: 50px 15px 0;
    margin-bottom: 10px;
    color: #02959c;
    line-height: 1.5;
    // margin: 0 0 1em 0;
  }

  .frame-content {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 80px;
    img {
      height: 35px;
      width: 35px;
      display: inline-block;
      vertical-align: text-top;
      margin: auto 16px;
    }
    .line-content {
      margin-top: 15px;
      width: 60px;
      height: 4px;
      background-color: #02959c;
    }
  }
  .single-fb {
    margin: 0 auto;
    max-width: 600px;
    .single-cmt-fb {
      display: flex;
      margin-bottom: 20px;
      max-width: 600px;
      .ava-fb {
        float: left;
        width: 15%;
        img {
          max-width: 60px;

          padding-top: 10px;
        }
      }
      .cmt-fb {
        width: 80%;
        float: right;
        position: relative;
        img {
          width: 100%;
        }
        .fb-section {
          position: absolute;
          top: 18px;
          left: 25px;
          width: 95%;
          .fb-name {
            text-align: left;
            font-family: "Quicksand Bold" !important;
            font-size: 17px;
            margin-bottom: 0;
          }
          .fb-txt {
            font-family: "Quicksand Regular" !important;
            font-size: 18px;
            text-align: left;
            margin-bottom: 0;
            margin-right: 40px;
            line-height: 1.3;
          }
        }
      }
      .fb-date {
        position: absolute;
        bottom: -6px;
        left: 10px;
        p {
          span {
            margin-right: 10px;
            font-family: "Quicksand Bold" !important;
            font-size: 16px;
          }
          .fb-time {
            font-family: "Quicksand Regular" !important;
            font-size: 16px;
          }
        }
      }
      .fb-like {
        position: absolute;
        bottom: -4px;
        right: 55px;
        p {
          font-family: "Quicksand SemiBold" !important;
          font-size: 16px;
        }
      }
    }
  }
  .show-price {
    background-image: url("@/assets/img/bg-sp.jpg");
    background-repeat: no-repeat;
    background-position-x: center;
    padding-top: 200px;
    background-size: cover;
    background-color: #e05d4b;
    .container{
      margin-bottom: -50px;
    }
    .title-price {
      font-family: Soup;
      font-size: 40px;
      text-align: center;
      padding: 50px 15px 0;
      margin-bottom: 10px;
      color: white;
      text-shadow: 2px 2px 10px black;
    }
    .frame-content {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      img {
        height: 35px;
        width: 35px;
        display: inline-block;
        vertical-align: text-top;
        margin: auto 16px;
      }
      .line-content {
        margin-top: 15px;
        width: 60px;
        height: 4px;
        background-color: #ffffff;
      }
    }
    .img-content {
      display: flex;
      justify-content: center;
      color: #ffffff;
      .table-price {
        width: 100%;
        margin-top: -15px;
      }
      .sp-img {
        width: 100%;
        img {
          width: 100%;
          max-width: 500px;
        }
      }
      .sp-single {
        border-right: 0;
        background: #e35b898c;
        color: white;
        padding: 0;
        border: solid 2px white;
        margin-top: 50px;
        width: 100%;
        // height: 440px;
        .object-use {
          padding: 15px 0;
          text-align: center;
          font-family: "Quicksand SemiBold";
          font-size: 22px;
          line-height: 22px;
          //   margin-bottom: 10px;
          border-bottom: solid 2px white;
        }
        .sp-title {
          font-family: Soup;
          font-size: 30px;
          text-align: center;
          margin-bottom: 0;
          line-height: 1.5;
          //   margin: 0 0 1em 0;
        }
        .sp-sub {
          font-family: "Quicksand Bold";
          font-size: 20px;
          line-height: 22px;
          margin-bottom: 20px;
          text-align: center;
        }
        .sp-price {
          .new-price {
            font-family: "Quicksand Bold";
            font-size: 60px;
            line-height: 42px;
            text-align: center;
            margin-bottom: 0px;
            line-height: 1.5;
            // margin: 0 0 1em 0;
          }
          .freeship {
            line-height: 1.5;
            margin: 0 0 1em 0;
            font-family: Soup;
            font-size: 25px;
            text-align: center;
            margin-bottom: 0;
            color: #fed196;
          }
          .old-price {
            font-family: "Quicksand Bold";
            font-size: 30px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 50px;
            margin: 0 0 1em 0;
          }
        }
        .sp-footer {
          background: #e35b89;
          text-align: center;
          padding: 10px 0;
          border-top: solid 2px white;
          margin-bottom: 0;
          .sp-button {
            font-family: Soup;
            font-size: 35px;
            color: white;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
      .single-2 {
        background: #02959c80;
        
        .sp-footer {
          background: #02959c !important;
        }
      }
      .content {
        display: flex;
        height: auto;
      }
    }
  }
  .single-2:hover {
    background: #02959c !important;
  }
  .single-1:hover {
    background: #e35b89 !important;
  }
}
@media only screen and (max-width: 991px) {
  .container-feedback {
    .title-feedback {
      font-size: 33px;
    }
    .show-price {
      .title-price {
        font-size: 33px;
      }

      .img-content {
        display: block !important;
        color: #ffffff;
        .sp-img {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          margin-top: 40px;
          img {
            width: 100%;
          }
        }
        .sp-single {
          .object-use {
            font-size: 16px;

            p {
              margin-bottom: 0;
            }
          }
          .sp-title {
            font-size: 22px;
          }
          .sp-sub {
            font-size: 16px;
          }
          .sp-price {
            .new-price {
              font-size: 38px;
            }
            .freeship {
              font-size: 20px;
            }
            .old-price {
              font-size: 22px;
            }
          }
          .sp-footer {
            .sp-button {
              font-size: 28px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
.img-large{
  display: block;
}
.img-min{
  display: none;
}
  .container-feedback {
    .container{
      
    }
    .title-feedback {
      font-size: 33px;
    }
    .single-fb {
      margin: 0 auto;
      max-width: 600px;
      .single-cmt-fb {
        display: flex;
        margin: 0 aut0;
        max-width: 600px;
        .ava-fb {
          float: left;
          
          width: 15%;
          img {
            max-width: 55px;
            padding-top: 10px;
            src: "@/assets/img/wp-content/themes/obaby/includes/img/cmt-fb-2.jpg";
          }
        }
        .cmt-fb {
          width: 86%;
          float: right;
          position: relative;
          img {
            width: 100%;
          }
          .fb-section {
            position: absolute;
            top: 10px;
            width: 92%;
            .fb-name {
              text-align: left;
              font-family: "Quicksand Bold" !important;
              font-size: 19px;
              margin-bottom: 0;
            }
            .fb-txt {
              font-family: "Quicksand Regular" !important;
              font-size: 18px;
              text-align: left;
              margin-bottom: 0;
              margin-right: 20px;
              line-height: 1.3;
            }
          }
        }
        .fb-date {
          position: absolute;
          bottom: -14px;
          left: 10px;
          p {
            span {
              margin-right: 10px;
              font-family: "Quicksand Bold" !important;
              font-size: 12px;
            }
            .fb-time {
              font-family: "Quicksand Regular" !important;
              font-size: 12px;
            }
          }
        }
        .fb-like {
          position: absolute;
          bottom: -14px;
          right: 50px;
          p {
            font-family: "Quicksand SemiBold" !important;
            font-size: 14px;
          }
        }
      }
    }
    .show-price {
      .img-content {
        .sp-img {
          max-width: 250px;
        }
      }
      .content {
        display: block !important;
        height: auto;
      }
    }
  }
}
</style>