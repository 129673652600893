<template>
  <div>
    <section class="bmi-banner" id="#bmi-banner">
      <div class="banner-wrap">
        <img
          src="@/assets/img/banner-bmi.jpg"
          alt=""
          width="100%"
          class="lazyloaded-banner"
        />
        <img
          src="@/assets/img/bg-bmi-m.jpg"
          alt=""
          width="100%"
          class="lazyloaded-banner-m"
          style="display:none;"
        />

        <div class="banner-infor">
          <p class="banner-lead">Kiểm tra thể trạng của bé</p>
          <p class="banner-sub">
            Theo chuẩn BMI mới nhất dành cho trẻ em Châu Á năm 2021
          </p>
          <div class="wrapper">
            <a href="#bmiform" class="button-banner">KIỂM TRA NGAY!</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.bmi-banner {
  display: block;
  margin-top: 104px;
}
.lazyloaded-banner {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 0ms;
}
.banner-infor {
  position: absolute;
  top: 30%;
  left: 50px;
  width: 45%;
}
.banner-lead {
  font-family: Soup;
  font-size: 62px;
  line-height: 45px;
  margin-bottom: 40px;
  color: #031a45;
  margin-left: -50px;
}
.banner-sub {
  font-family: "Quicksand SemiBold";
  font-size: 22px;
  color: #02959c;
  font-style: italic;
  margin-bottom: 30px;
  margin-left: -150px;
}
.wapper {
  padding: 1.5rem 0;
}
.bmi-banner .banner-infor .button-banner {
  display: inline-block;
  text-align: center;
  background: var(--color);
  color: var(--bg);
  font-weight: bold;
  padding: 18px 50px;
  line-height: 1;
  border-radius: 1em;
  position: relative;
  min-width: 8.23em;
  text-decoration: none;
  font-family: "Quicksand SemiBold";
  font-size: 18px;
  background: #02959c;
  color: white;
  margin-left: -530px;
  margin-top: 18px;
}
@media only screen and (max-width: 1500px) {
  
  
  .banner-lead {
    margin-top: -50px;
    line-height: 60px;
  }
  .banner-sub {
    width: 500px;
    margin-left: -20px;
   left: 0;
  }
  .bmi-banner .banner-infor .button-banner {
    margin-top: -50px;
    margin-left: -90px;
    left: 0;
  }
  .button-banner{
    display: inline-block;
    text-align: start;
  }
}
@media only screen and (max-width: 900px) {
  
  .banner-lead {
    
    font-size: 55px;
    width:600px;
    text-align: start;
    margin-left: -2px;
    margin-top: -90px;
  }
  .banner-sub {
   
    width: 500px;
    margin-left: -20px;
    text-align: start;
  }
  .lazyloaded-banner{
    display: none;
  }
  .lazyloaded-banner-m{
    display: block !important;
  }
}

@media only screen and (max-width: 750px) {
  .banner-infor{
    margin-top: -100px;
    height: 150px;
  }
  .banner-lead {
    margin-top: 20px ;
    font-size: 45px;
    line-height: 54px;
    width:400px;
    margin-bottom: -2px;
    margin-left: -20px
  }
  .banner-sub {
    margin-top: 0px;
    width: 260px;
    margin-left: -20px;
    text-align: start;
    font-size: 17px;
    margin-bottom: 30px;
  }
  .button-banner{
    font-size: 16px !important;
    margin-left: 20px;
    margin-bottom: 30px;
    padding: 20px 30px !important;
  }
  .wrapper{
    text-align: start;
    margin-left: 70px;
  }
}
@media only screen and (max-width: 600px){
  .banner-infor {
  position: absolute;
  top: 25%;
  left: 50px;
  width: 45%;
}
.banner-wrap{
  margin-top: -40px;
}
.banner-sub{
  font-size: 16px;
}
.banner-lead{
  margin-top: 0;
  font-size: 32px;
}
}
@media only screen and (max-width: 450px){
.banner-lead{
  margin-top: -10px;
  font-size: 24px;
  width: 300px;
}
.banner-sub{
  font-size: 14px;
}

.button-banner{
  font-size: 13px !important;
  padding: 15px 20px !important;
}
}
@media only screen and (max-width: 350px){
  .banner-infor {
  position: absolute;
  top: 25%;
  left: 25px !important;
  width: 45%;
}
.banner-lead{
  margin-top: 0;
  font-size: 20px;
  width: 250px;
  
}
.banner-sub{
  font-size: 10px;

}

.button-banner{
  font-size: 10px !important;
  padding: 10px 15px !important;
}
}
</style>