<template>
  <div>
    <section
      class="bmipara"
      style="
        overflow: hidden;
        background-image: url('@/assets/img/bg-bmi-test.jpg');
      "
    >
      <div class="container-para">
        <div class="row-para">
          <div class="col-sm-6 bmip-infor">
            <p class="bmip-title">Chỉ số BMI là gì</p>
            <div class="under-leading-white">
              <div class="ul-wrap">
                <div class="center-wrap">
                  <div class="obb-icon">
                    <div class="line-wrap"></div>
                    <img
                      class="lazyloaded-banner"
                      src="@/assets/img/obb-icon-w.png"
                    />
                    <div class="line-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
            <p style="text-align: justify">
              <strong>BIM</strong> là viết tắt của
              <strong>Body Mass Index</strong> , là phép tính sử dụng chiều cao
              và cân nặng của trẻ để tính ước lượng mỡ cơ thể. Đối với trẻ em và
              thanh thiếu niên, BMI được đặc trưng theo cả độ tuối và giới tính
              nên chỉ số này được gọi là chỉ số BMI theo tuổi. Ở trẻ em, nếu
              thiếu cân dễ gây nguy cơ tiềm tàng cho sức khỏe và ngược lại, nếu
              lượng mỡ cơ thể cao có thể dẫn đến các bệnh có liên quan đến cân
              nặng của trẻ.
            </p>
          </div>
          <div class="col-ms-6 bmip-img">
            <img
              class="ls-is-cached lazyloaded"
              id="bmip-right"
              src="@/assets/img/bmi-1.png"
            />
          </div>
          <br />
          <div class="col-ms-6 bmip-img">
            <img
              class="lazyloaded"
              id="bmip-left"
              src="@/assets/img/bmi-2.png"
            />
          </div>
          <div class="col-sm-6 bmip-infor" id="item-right">
            <p class="bmip-title">
              Sự khác nhau của chỉ số BMI trẻ em và người lớn
            </p>
            <div class="under-leading-white">
              <div class="ul-wrap">
                <div class="center-wrap">
                  <div class="obb-icon">
                    <div class="line-wrap"></div>
                    <img
                      class="lazyloaded-banner"
                      src="@/assets/img/obb-icon-w.png"
                    />
                    <div class="line-wrap"></div>
                  </div>
                </div>
              </div>
            </div>
            <p style="text-align: justify">
              Không giống với cách phân loại BMI ở người lớn, trẻ em từ 2 đến 20
              tuổi có cân nặng và chiều cao thay đổi nhanh chóng trong quá trình
              tăng trưởng và phát triển, chỉ số BMI ở trẻ được hiểu là cách so
              sánh tương đối với các trẻ khác có cùng độ tuổi và giới tính. Sau
              khi tính được chỉ số BMI cho một số lượng lớn trẻ em, các chỉ số
              này sẽ được thể hiện dưới dạng phần trăm của biểu đồ tăng trưởng
              trẻ em. Dựa vào biểu đồ này sẽ so sánh được chỉ số BMI của trẻ so
              với các trẻ em khác.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.bmipara {
  background: #031a45;
  padding-top: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.container-para {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row-para {
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
}
.bmipara .bmip-infor {
  margin-bottom: 70px;
  max-width: 555px;
  margin: 20px;
}
.bmipara .bmip-infor .bmip-title {
  font-family: Soup;
  color: white;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 15px;
  left: 0;
  width: 100%;
}
.bmipara .bmip-infor .under-leading-white {
  margin-bottom: 30px;
}
.center-wrap {
  text-align: center;
}
.under-leading-white img {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: text-top;
}

.line-wrap {
  margin-top: 15px;
  width: 60px;
  height: 4px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}

.obb-icon {
  display: flex;
  justify-content: center;
}
.bmipara .bmip-infor p {
  font-family: "Quicksand Regular";
  color: white;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  width: 100%;
}
.bmipara .bmip-img img {
  margin: 0 auto;
  width: 555px;
  display: block;
  margin-left: 0;
}
.lazyloaded-banner {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 0ms;
}
.bmipara .bmip-img .bmip-left {
  width: 50%;
}
.bmipara .bmip-img {
  margin-bottom: 70px;
  height: 369px;
}
#item-right {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 440px;
  margin-right: 50px;
}
@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .bmip-img {
    height: 369px;
  }
  .bmip-infor {
    width: 45%;
  }
  .bmipara .bmip-infor .bmip-title {
    font-size: 30px !important;
  }
  .bmipara .bmip-infor p {
    font-size: 16px !important;
  }
  #bmip-left {
    width: 550px;
  }
  #bmip-right {
    width: 446px;
  }
  #item-right {
    margin-top: 400px;
  }
 
  
}
@media only screen and (max-width: 900px) and (min-width: 768px) {
  
  .bmip-infor {
    width: 45%;
  }
  .bmipara .bmip-infor .bmip-title {
    font-size: 25px !important;
  }
  .bmipara .bmip-infor p {
    font-size: 16px !important;
  }
  #bmip-left {
    width: 45%;
  }
  #bmip-right {
    width: 41%;
  }
  #item-right {
    margin-top: 400px;
  }
}
@media (max-width: 768px) {
  .bmipara .bmip-img img[data-v-3c7f1c73] {
    margin-left: 0;
    width: 95%;
    display: inline-block;
  }
  .bmip-infor {
    display: inline-block;
    width: 95%;
  }
  .bmipara .bmip-infor{
    max-width: 100%;
  }
  .bmip-title {
    width: 100%;
    display: inline-block;
  }
  #item-right {
    right: 0;
    position: relative;
    margin: 0;

  }
}
@media (max-width: 600px) {
.bmipara .bmip-infor {
  width: 90%;
  margin-left: 15px;
  margin-right: 15px;
}
.bmipara .bmip-img img{
  width: 90% !important;
  margin-top: 50px;

}
.bmiform-section .bmif-lead {
  
  width: 90%;
}

}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
}
@media (min-width: 1200px) {
  .container-para {
    width: 1170px;
  }
}
</style>