<template>
  <div class="banner-container bg-image" id = "banner-container">
    <div class="slider-info">
      <buyModal v-if="isShowBuyModal" @btClose="isShowBuyModal = false" />
      <contentModal
        v-if="isShowContentMoldal"
        @btCloseContent="isShowContentMoldal = false"
      />
      <p>Con ngoan <span style="color: #f5df50">ăn khỏe</span></p>
      <p>Mẹ trẻ thảnh thơi</p>
      <div class="slider-button">
        <div>
          <a class="order-buy" @click="isShowBuyModal = true">
            Đặt hàng ngay
          </a>
        </div>
        <div class="bt-item">
          <a class="order-support" @click="isShowContentMoldal = true">
            Đặt lịch tư vấn
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buyModal from "@/view/Dialog/buy-modal.vue";
import contentModal from "@/view/Dialog/content-modal.vue";

export default {
  data() {
    return {
      isShowBuyModal: false,
      isShowContentMoldal: false,
    };
  },
  components: {
    buyModal,
    contentModal,
  },
};
</script>

<style lang="scss" scoped>
.banner-container {
  background-image: url("@/assets/img/slider-1.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 104px;
  height: 600px;
  .slider-info {
  
    position: absolute;
    top: 32%;
    right: 4%;
    p {
      font-family: Soup;
      color: white;
      font-size: 60px;
      line-height: 60px;
      text-align: end;
    }
    .slider-button {
      float: right;
      margin-top: 22px;
      display: flex;
      a {
        background: #fff;
        padding: 20px 50px;
      
        border-radius: 20px;
        font-family: "Quicksand Bold";
        font-size: 25px;
        letter-spacing: 0.1px;
        cursor: pointer;
        line-height: 25px;
        text-decoration: none !important;
      }
      .order-buy {
        background: white;
        color: #02959c;
      }

      .order-support {
        background: #02959c;
        color: white;
      }
    }
  }
}
.order-buy{
  margin-right: 13px !important;
}
.order-buy:hover{
  background-color:#02959c !important;
  color: #fff !important;
}
.order-support{
  margin-left: 13px;
}
@media only screen and (max-width: 991px) {
  .banner-container {
    .slider-info {
      top: 22% !important;
      p {
        font-size: 35px !important;
        line-height: 35px !important;
      }
      .slider-button {
        display: block;
        a {
          font-size: 20px;
          padding: 10px 30px !important;
          margin-left: 10px;
        }
        .bt-item {
          margin-top: 28px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .banner-container {
    background-image: url("@/assets/img/slider-m-1.jpg");
    background-position: center;
    background-size: cover;
    margin-top: 64px;
    height: 500px;
  }
}
</style>
