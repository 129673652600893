<template>
  <div class="buy-modal">
    <div class="bg-buy" @click="btClose"></div>
    <div class="modal-content" v-if="!isShowSuccess">
      <span class="close" @click="btClose">×</span>
      <div class="frame">
        <div class="buy-left">
          <img src="@/assets/img/tuvan.png" alt="" />
        </div>
        <div class="buy-right">
          <p class="buy-lead">THÁCH THỨC BÉ BIẾNG ĂN!</p>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Sản phẩm</th>
                <th scope="col">Giá</th>
                <th scope="col">Chọn</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataModalBuyLst" :key="index">
                <td style="text-align: left">{{ item.product }}</td>
                <td>{{ item.price }}</td>
                <td>
                  <label class="container-box">
                    <input type="checkbox" v-model="item.choose" />
                    <span class="checkmark"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row input-data">
            <div class="col-lg col-md col-sm-12 name">
              <input
                type="text"
                placeholder="Họ và tên"
                v-model="formRegister.FullName"
              />
            </div>
            <div class="col-lg col-md col-sm-12 phone">
              <input
                type="text"
                placeholder="Số điện thoại"
                v-model="formRegister.PhoneNumber"
              />
            </div>
          </div>
          <p
            v-if="isShowWarning"
            class="mt-2"
            style="color: #fff; margin-bottom: -10px"
          >
            {{ contentWarning }}
          </p>
          <button type="button" class="bt-register" @click="createWebRegister">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              v-if="loadding"
            ></span>
            Đăng ký ngay
          </button>
        </div>
      </div>
    </div>
    <div class="buy-success" v-else>
      <i class="fa-solid fa-circle-check"></i>
      <p class="title">Thành công</p>
      <p class="sub">
        Cám ơn mẹ! Nhân viên CSKH sẽ sớm liên lạc lại với mẹ bé trong thời gian
        sớm nhất!
      </p>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";

export default {
  data() {
    return {
      isShowHideBuyModal: false,
      dataModalBuyLst: [
        {
          product: "1 hộp Obaby 123 (dành cho bé dưới 3 tuổi)",
          price: "120.000đ",
          choose: false,
        },
        {
          product: "1 hộp Obaby (dành cho bé trên 2 tuổi)",
          price: "240.000đ",
          choose: false,
        },
        {
          product: "Liệu trình 1 tháng Obaby 123 (2 hộp) - Freeship",
          price: "240.000đ",
          choose: false,
        },
        {
          product: "Liệu trình 2 tháng Obaby (2 hộp) - Freeship",
          price: "440.000đ",
          choose: false,
        },
      ],
      loadding: false,
      formRegister: {
        PhoneNumber: "",
        FullName: "",
      },
      isShowWarning: false,
      isShowSuccess: false,
      contentWarning: "Các mẹ vui lòng điền đủ thông tin!",
      seconds: "",
      minutes: "",
      hours: "",
      dates: "",
      months: "",
      years: "",
      monthsStart: "",
      timeEnd: "",
      timeStart: "",
    };
  },
  methods: {
    btClose() {
      this.$emit("btClose", false);
    },
    createWebRegister() {
      var check = this.dataModalBuyLst.filter((p) => p.choose == true);
      if (check.length == 0) {
        this.isShowWarning = true;
        this.contentWarning = "Các mẹ vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Các mẹ vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.formRegister.Desctiption = "";
      for (var i = 0; i < check.length; i++) {
        this.formRegister.Desctiption += check[i].product + ";";
      }

      this.loadding = true;
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "Obaby",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.formRegister.Desctiption,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.btClose();
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buy-modal {
  position: fixed;
  z-index: 1000;
  padding-top: 150px;
  padding-bottom: 200px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  .buy-success {
    z-index: 102 !important;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    background-color: #fff !important;
    padding: 30px;
    max-width: 400px !important;
    i {
      color: rgb(37, 221, 46);
      font-size: 70px;
    }
    .title {
      font-family: "Quicksand SemiBold";
      font-size: 24px;
      margin-top: 10px;
      color: #02959c;
    }
    .sub {
      font-family: "Quicksand Medium";
      font-size: 18px;
      color: #02959c;
    }
  }
  .modal-content {
    z-index: 101;
    border-radius: 8px;
    margin: auto;
    overflow: hidden;
    background-image: url("@/assets/img/bg-buy.jpg");
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 80% !important;

    .close {
      position: absolute;
      top: 30px;
      right: 40px;
      color: white;
      cursor: pointer;
      width: 16px;
      font-size: 28px;
      font-weight: bold;
    }
    .frame {
      display: flex;
    }
    .buy-left {
      width: 35%;
      img {
        max-width: 420px;
        margin: 30px auto 0;
        width: 100%;
      }
    }
    .buy-right {
      width: 65%;
      margin-right: 30px;
      .buy-lead {
        font-family: "Quicksand Bold";
        font-size: 33px;
        color: white;
        text-transform: uppercase;
        text-shadow: 2px 2px 10px #445;
        margin: 30px;
      }
      .table {
        color: #fff;
        thead {
          border: 2px solid #fff;
        }
        tbody {
          border: 2px solid #fff;
        }
        th {
          border: 2px solid #fff;
        }
        td {
          border: 2px solid #fff;
        }
        .container-box {
          position: relative;
          .container-box input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: -5px;
            height: 28px;
            width: 28px;
            background-color: #eee;
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
        .container-box:hover input ~ .checkmark {
          background-color: #ccc;
        }
        .container-box input:checked ~ .checkmark {
          background-color: #02959c;
        }
        .container-box input:checked ~ .checkmark:after {
          display: block;
        }
        .container-box .checkmark:after {
          left: 10px;
          top: 5px;
          width: 8px;
          height: 14px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      .input-data {
        margin-top: -18px;
        margin-left: 0px;
        width: 100%;
        .name {
          border: 3px solid #fff;
          padding: 5px;
          width: 100%;
        }
        .phone {
          border: 3px solid #fff;
          padding: 5px;
          width: 100%;
        }
        input {
          width: 100%;
          height: 40px;
          font-size: 17px !important;
          border: none !important;
          border-radius: 0 !important;
          font-family: "Quicksand Regular" !important;
          outline: 0 !important;
          padding: 10px !important;
        }
      }
      .bt-register {
        text-transform: uppercase;
        border: none;
        background: #02959c;
        color: #fff;
        font-family: "Quicksand Bold" !important;
        font-size: 20px;
        margin-top: 20px;
        width: 100%;
        height: 44px;
        padding: 12px auto;
      }
    }
  }
  .bg-buy {
    position: fixed;
    z-index: -1 !important;
    padding-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width: 991px) {
  .buy-modal {
    .modal-content {
      display: block;
      .close {
        top: 20px;
        right: 20px;
      }
      .frame {
        display: block;
        .buy-left {
          width: 100%;
          img {
            max-width: 220px;
            margin: 20px auto 0;
          }
        }
        .buy-right {
          width: 100%;
          padding: 20px;
          margin-top: -30px;
          .buy-lead {
            font-size: 23px;
            margin: 10px;
          }
        }
      }
    }
  }
}
</style>
