<template>
  <div>
    <header class="navbar-obaby" id="navbar-obaby-direc">
      <buyModal v-if="isShowBuyModal" @btClose="isShowBuyModal = false" />
      <contentModal
        v-if="isShowContentMoldal"
        @btCloseContent="isShowContentMoldal = false"
      />
      <nav
        class="navbar bg-light fixed-top container"
        style="
          background: #02959c !important;
          width: 100% !important;
          display: flex;
        "
      >
        <div class="container-fluid" style="width: 73%">
          <a href="/">
            <img
              src="@/assets/img/logo.png"
              alt=""
              class="layout-imglogo"
              style=""
            />
          </a>

          <div class="menu-option" style="margin-right: -150px">
            <ul class="me-auto mb-2 mb-lg-0" style="display: flex">
              <li class="nav-item nav-des">
                <a class="nav-link" aria-current="page" href="/">Trang chủ</a>
              </li>
              <li class="nav-item nav-des" style="cursor: pointer">
                <a class="nav-link" @click="btBmi">Kiểm tra thể trạng bé</a>
              </li>

              <li class="nav-item nav-des" style="cursor: pointer">
                <a class="nav-link" @click="btHelp">Giúp bé ăn ngon</a>
              </li>
              <li class="nav-item nav-des" style="cursor: pointer">
                <a class="nav-link" @click="btLocal">Điểm bán NT</a>
              </li>
            </ul>
          </div>
          <button
            class="navbar-toggler mobile"
            @click="(isShowNavRespone = true), (isShowSpaceNav = true)"
          >
            <!-- <span class="navbar-toggler-icon"></span> -->
            <i
              class="fa-solid fa-bars"
              style="color: #fff; font-size: 28px"
            ></i>
          </button>
        </div>
      </nav>
      <div
        class="navbar-responesive"
        v-if="isShowNavRespone"
        style="background: #02959c"
      >
        <div class="icon-container">
          <div class="icon-responesive">
            <i
              class="fa-solid fa-xmark"
              @click="(isShowNavRespone = false), (isShowSpaceNav = false)"
            ></i>
          </div>
        </div>
        <div class="container-responesive">
          <ul class="items-responesive">
            <li class="elements-responesive">
              <a
                class=""
                style="color: #fff"
                href="/"
                @click="(isShowNavRespone = false), (isShowSpaceNav = false)"
                >Trang chủ</a
              >
            </li>
            <li class="elements-responesive">
              <a class="" style="color: #fff; cursor:pointer" @click="btHelpNav"
                >Giúp bé ăn ngon</a
              >
            </li>
            <li class="elements-responesive">
              <a
                class=""
                style="color: #fff;cursor:pointer"
                @click="btBmiNav "
                >Kiểm tra thể trạng bé</a
              >
            </li>
            <li class="elements-responesive">
              <a
                @click=" btLocalNav "
                class=""
                style="color: #fff;cursor:pointer"
                >Điểm bán Nhà thuốc</a
              >
            </li>

            <li
              class="elements-responesive"
              style="
                background: #e35b89;
                border-bottom: 2px solid #fff;
                cursor: pointer;
              "
            >
              <a
                class="elements-responesive"
                style="color: #fff"
                aria-current="page"
                @click="(isShowBuyModal = true), (isShowNavRespone = false)"
                data-bs-toggle="offcanvas"
                >Đặt hàng ngay</a
              >
            </li>
          </ul>
          <li class="img_responesive">
            <img src="@/assets/img/logo.png" alt="" width="80%" class="mt-4" />
          </li>
        </div>
        <div
          class="d-flex"
          style="
            border-top: 2px solid #fff;

            bottom: 0;
            right: 0;
            width: 65%;
            font-family: 'Quicksand Bold' !important;
            margin-left: 0;
          "
        >
          <div
            class="col-md-4 col-sm-4 col-xs-4 pt-2"
            style="
              background: #02959c !important;
              width: 33.3333%;
              cursor: pointer;
            "
          >
            <p
              style="color: #fff"
              @click="(isShowContentMoldal = true), (isShowNavRespone = false)"
              data-bs-toggle="offcanvas"
            >
              Tư vấn ngay
            </p>
          </div>
          <div
            id="phone-number-nav"
            class="col-md-4 col-sm-4 col-xs-4 pt-2"
            style="
              background: #e35b89 !important;
              width: 33.3333%;
              cursor: pointer;
            "
          >
            <i
              class="fa-solid fa-phone-flip"
              style="color: #fff; margin-right: 4px"
            ></i>
            <a style="color: #fff; text-decoration: none" href="tel:0971356356"
              >0971.356.356</a
            >
          </div>
          <div
            class="col-md-4 col-sm-4 col-xs-4 pt-2"
            style="
              background: #02959c !important;
              width: 33.3333%;
              cursor: pointer;
            "
          >
            <p
              style="color: #fff"
              @click="(isShowBuyModal = true), (isShowNavRespone = false)"
              data-bs-toggle="offcanvas"
            >
              Mua ngay
            </p>
          </div>
        </div>
      </div>
    </header>
    <div
      class="close-space-nav"
      v-if="isShowSpaceNav"
      @click="(isShowNavRespone = false), (isShowSpaceNav = false)"
    ></div>
  </div>
</template>

<script>
import buyModal from "@/view/Dialog/buy-modal.vue";
import contentModal from "@/view/Dialog/content-modal.vue";

export default {
  data() {
    return {
      isShowSpaceNav: false,
      isShowNavRespone: false,
      isShowBuyModal: false,
      isShowContentMoldal: false,
      menuLst: [
        {
          value: 1,
          label: "Trang chủ",
          key: "",
          link: "/",
        },
        {
          value: 2,
          label: "Kiểm tra thể trạng bé",
          link: "/bmi",
          key: "bmi",
        },
        {
          value: 3,
          label: "Trị mất ngủ",
          link: "/blogs",
          key: "blogs",
        },
        {
          value: 4,
          label: "Điểm bán",
          link: "/diem-ban",
          key: "diemban",
        },
      ],
    };
  },
  components: {
    buyModal,
    contentModal,
  },
  methods: {
    btCloseNav() {
      this.$emit("btnShowNav", false);
    },
    isShowNav() {
      location.reload();
      // if(k[0].style.display == "none")
      // {
      //   k[0].style.display = "block";
      // }
      // else
      // {
      //   k[0].style.display = "none";
      // }
      // if(j[0].style.display == "none")
      // {
      //   j[0].style.display = "block";
      // }
      // else
      // {
      //   j[0].style.display = "none";
      // }
    },
    btLocal() {
      this.$router.push("/diem-ban");
    },
    btBmi() {
      this.$router.push("/bmi");
    },
    btHelp() {
      this.$router.push("/blogs");
    },
    btHelpNav() {
      this.$router.push("/blogs");
      this.isShowNavRespone = false;
      this.isShowSpaceNav = false;
    },
    btBmiNav() {
      this.$router.push("/bmi");
      this.isShowNavRespone = false;
      this.isShowSpaceNav = false;
    },
    btLocalNav() {
      this.$router.push("/diem-ban");
      this.isShowNavRespone = false;
      this.isShowSpaceNav = false;
    },
    hideMenu() {
      // document.getElementById("offcanvasNavbar").style.display = "none";
      // document.getElementsByClassName("offcanvas-backdrop")[0].style.display =
      //   "none";
      //  var k = document.getElementById("offcanvasNavbar");
      // var j = document.getElementsByClassName("offcanvas-backdrop")[0]
      //  k.classList.value = "offcanvas offcanvas-end";
      //  k.classList.remove = "show";
      //  j.classList.value = "offcanvas-backdrop fade";
      //   j.classList.remove = "show";
      //   console.log(j.classList);
      // k.style.display = "block";
      // k.removeAttribute("aria-modal", "role");
    },
    showMenu() {
      // document.getElementById("offcanvasNavbar").style.display = "block";
      // document.getElementsByClassName("offcanvas-backdrop")[0].style.display =
      //   "block";
      //  var k = document.getElementById("offcanvasNavbar");
      // var j = document.getElementsByClassName("offcanvas-backdrop")[0]
      //  k.classList.value = "offcanvas offcanvas-end show";
      //  k.classList.remove = "show";
      //  j.classList.value = "offcanvas-backdrop fade show";
      //   j.classList.remove = "show";
      //   console.log(j.classList);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-des {
  font-family: "Quicksand Medium";
}
.navbar-obaby {
  height: 96px;
  margin-top: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.layout-imglogo {
  height: 88px;
}
@media (max-width: 550px) {
  #offcanvasNavbar {
    width: 65%;
  }
}
@media (min-width: 1200px) {
  .mobile {
    display: none;
  }
}
@media (max-width: 450px) {
  .items-responesive {
    width: 96%;
  }
  .elements-responesive a {
    font-size: 15px !important;
  }
}
@media (max-width: 1200px) {
  .menu-option {
    display: none;
  }
  .container-fluid {
    width: 100% !important;
  }
}
.offcanvas-body {
  padding: 0 !important;
}
.nav-mobile {
  list-style: none;
  margin: 0;
  font-size: 20px;
  color: #fff;
  border-top: 2px solid #fff !important;
}
.option-menu-mobile {
  padding: 0 !important;
  text-align: left;
  margin: 16px;
  font-family: "Quicksand Bold" !important;
}
.nav-des {
  list-style: none;
  color: #fff;
  font-size: 18px;
  vertical-align: middle;
  margin-left: 24px;
}
.nav-des:hover {
  color: #f5df50 !important;
}
.navbar-responesive {
  right: 0;
  width: 65%;
  z-index: 2000;
  position: absolute;
  transition: opacity 0.25s ease-out;
}
.icon-container {
  height: 100px;
}
.icon-responesive {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.icon-responesive i {
  font-size: 30px;
  margin-right: 40px;
  cursor: pointer;
}
.elements-responesive {
  height: 66px;
  border-top: #dcd7ca solid 1px;
  display: flex;
  align-items: center;
  width: 95%;
}
.elements-responesive a {
  font-size: 17px;
  list-style-type: none;
  font-weight: 700px;
  text-decoration: none;
  font-family: "Quicksand Bold";
  padding: 25px 0 25px 20px;
}
.img_responesive {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -20px;
}
.img_responesive img {
  max-width: 300px;
  max-height: 76px;
  margin-left: 30px;
}
.d-flex {
  position: fixed !important;
}
.container {
  max-width: 100% !important;
}
.close-space-nav {
  width: 70%;
  z-index: 30;
  height: 100%;
  position: fixed;
  background-color: black;
  opacity: 0.25;
}

@media only screen and (max-width: 600px) {
  .navbar-obaby {
    height: 64px;
  }
  .layout-imglogo {
    height: 48px !important;
  }
  .d-flex {
    width: 75% !important;
  }
  .d-flex p {
    font-size: 13px;
  }
  #phone-number-nav {
    font-size: 13px;
  }
  #phone-number-nav i {
    margin-right: 5px !important;
    height: 1px;
    width: 10px;
  }
  .icon-container {
    height: 60px !important;
  }
  .navbar-responesive {
    width: 75%;
  }
}
</style>
