<template>
  <div style="width: 100%; margin-top: -10px">
    <banner />
    <content1 />
    <news />
    <register />
    <intro />
    <medicine />
    <register />
    <company />
    <feedback />
    <register />
    <handbook />
    <!-- <feedback />
    <register />
    <handbook />-->
    <footerobaby />
  </div>
</template>

<script>
// import buyModal from "../Dialog/buy-modal.vue";
import content1 from "./components/content-obaby.vue";
import news from "./components/news-obaby.vue";
import intro from "./components/intro-obaby.vue";
import medicine from "./components/medicine-obaby.vue";
import register from "./components/register-obaby.vue";
import company from "./components/intro-company.vue";
import banner from "./components/banner-obaby.vue";
import feedback from "./components/feedback-obaby.vue";
import handbook from "./components/handbook-obaby.vue";
import footerobaby from "./components/footer-obaby.vue";
export default {
  components: {
    banner,
    content1,
    news,
    intro,
    medicine,
    register,
    company,
    feedback,
    handbook,
    footerobaby,
    // buyModal,
  },
};
</script>

<style>
em {
  font-size: 16px !important;
  line-height: 0;
  font-weight: 500;
  font-style:normal;
}


</style>
